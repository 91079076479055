import { replaceASCII } from "./stringUtils.mjs"
import { ROUND } from "./financeUtils.mjs"
import writtenNumber from "./written-number/index.mjs"

function extractNumberFromString(str) {
  if (!str) return undefined
  let [num] = (str.match(/\d+/g) || []).map(Number)
  if (str.startsWith("-")) num = -num
  return num
}

/**
 * @param {number} number
 * @param {size=2} size
 * @returns Returns the provided number as a string left-padded with zeroes up to the specified size.
 */
function padNumber(number, size = 2) {
  return number < Math.pow(10, size - 1) ? "0".repeat(size - number.toString().length) + number : number
}

/**
 * @param {number} number
 * @param {string} locale
 * @param {object} options
 * @returns Returns the string percentage representation of the number according to the locale. See [Intl.NumberFormat()](https://v8.dev/features/intl-numberformat) for details regarding the option field.
 */
function formatPercentage(number, locale, options) {
  if (!number && number !== 0) return number

  // avoid -0.00
  if (Object.is(ROUND(number, 8), -0)) number = 0

  // this is a hack to make it simpler when we want no digits
  if (options === 0) return (100 * number).toFixed(0) + "%"
  else if (!options) options = {}

  if (!options.minimumFractionDigits) options.minimumFractionDigits = 2
  options.style = "percent"
  if (locale === "en-LT") locale = "lt-LT"
  return new Intl.NumberFormat(locale, options).format(number)
}

/**
 * @param {number} number
 * @param {string} locale
 * @param {object} options
 * @returns Returns the string decimal representation of the number formatted according to the locale. See [Intl.NumberFormat()](https://v8.dev/features/intl-numberformat) for details regarding the option field.
 */
function formatDecimal(number, locale, options) {
  if (!number) return number

  /// avoid -0.00
  if (Object.is(ROUND(number, 8), -0)) number = 0

  if (!options) options = {}
  options.maximumFractionDigits = options.maximumFractionDigits ?? 4

  let formattedDecimal = new Intl.NumberFormat(locale, options).format(number)
  if (locale === "fr-GN") {
    formattedDecimal = replaceASCII(formattedDecimal)
    formattedDecimal = formattedDecimal.replace(/ /g, ".")
  }
  if (locale === "en-LT") {
    formattedDecimal = formattedDecimal.replace(new RegExp(/,/g), "#").replace(new RegExp(/\./), ",").replace(new RegExp(/#/g), ".")
  }

  return formattedDecimal
}

/**
 * The Javascript language does not provide a native way to round accuratedly.
 * Use this function to compensate for the lack.
 * @param {Number} number The number to round accurately.
 * @param {Number} decimalPlaces The number of decimals. Default is 2.
 * @returns
 */
function roundAccurately(number, decimalPlaces = 2) {
  return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
}

/**
 * @param {number} number
 * @param {string} locale
 * @param {object} options
 * @param {*} options.currency TODO : document my type
 * @param {*} options.writtenDecimals TODO : document my type
 * @param {*} options.decimalSeparator TODO : document my type
 * @returns Returns the number written in words based on the locale (example : 1110 => One thousand one hundred ten) - handy for contracts.
 */
function formatDecimalToWords(number, locale, options = {}) {
  if (!number && number !== 0) return number
  return writtenNumber(number, locale && { lang: locale.split("-")[0], ...options })
}

/**
 * Counts the number of decimals of a number, accounting for scientific notation like 0.000005 as 5e-6.
 * Note that this function is not perfect : for numbers like 0.933029023222200002 it will return 13 instead of 18
 * due the Javascript way of handling floating numbers.
 */
function countDecimals(value) {
  const text = value.toString()

  if (text.indexOf("e-") > -1) {
    const [, trail] = text.split("e-")
    const deg = parseInt(trail, 10)
    return deg
  }

  if (Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0
  }

  return 0
}

/**
 * Checks if two numbers are approximately equal within a specified number of decimal places.
 * @param {number} num1 - The first number to compare.
 * @param {number} num2 - The second number to compare.
 * @param {number} decimalPlace - The number of decimal places to consider for the comparison.
 * @returns {boolean} True if the two numbers are close enough within the specified decimal places, false otherwise.
 */
function isApproximatelyEqual(num1, num2, decimalPlace) {
  const factor = Math.pow(10, decimalPlace)
  return Math.abs(num1 - num2) < 1 / factor
}

function isLessThan(num1, num2) {
  return typeof num1 === "number" && typeof num2 === "number" && num1 < num2
}

function isGreaterThan(num1, num2) {
  return typeof num1 === "number" && typeof num2 === "number" && num1 > num2
}

function isLessThanOrEqual(num1, num2) {
  return typeof num1 === "number" && typeof num2 === "number" && num1 <= num2
}

function isGreaterThanOrEqual(num1, num2) {
  return typeof num1 === "number" && typeof num2 === "number" && num1 >= num2
}

export {
  extractNumberFromString,
  padNumber,
  formatPercentage,
  formatDecimal,
  roundAccurately,
  formatDecimalToWords,
  countDecimals,
  isApproximatelyEqual,
  isLessThan,
  isGreaterThan,
  isLessThanOrEqual,
  isGreaterThanOrEqual,
}
