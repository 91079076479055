import React from "react"
import { handleAccessibleOnKeyDown } from "@/_services/utils"

class CustomCheckbox extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {}

  render() {
    const { checked, numberId, label, inline, displayMode, onDivClick, ...rest } = this.props // "onDivClick" instead of "onClick" to keep backward compatibility
    const classes = inline !== undefined || displayMode === "inline" ? "checkbox checkbox-inline" : "checkbox"
    return (
      <div className={classes} data-disabled={this.props.disabled} aria-checked={checked ? "true" : "false"} onClick={onDivClick}>
        <input
          id={numberId}
          type="checkbox"
          onChange={this.handleClick}
          onKeyDown={event => {
            handleAccessibleOnKeyDown({ event, fn: () => document.getElementById(numberId).click() })
          }}
          checked={checked ? 1 : 0}
          {...rest}
        />
        {/* &#8205; is a hidden html character, we need to pass accessibility checks  */}
        <label htmlFor={numberId}>{label || <>&#8205;</>}</label>
      </div>
    )
  }
}

export default CustomCheckbox
