import React from "react"
import { Col, ControlLabel, FormGroup, InputGroup } from "react-bootstrap"
import AsyncSelect from "react-select/async"
import debounce from "lodash.debounce"
import get from "lodash.get"
import set from "lodash/set"

import PersonQuickAddModal from "@/person/PersonQuickAddModal"
import EntitySearchModal from "@/_components/EntitySearchModal"
import EntityQuickAddModal from "@/_components/EntityQuickAddModal"
import FormInput, { getSelectComponents } from "@/_components/FormInput"
import { getEntities, getEntity, getOrganizationsObj, labelFromName, searchEntities } from "@/_services/utils.js"
import { chunk, getEntityClientRoute, isEqual } from "basikon-common-utils"
import { getEntityDisplay } from "@/_services/entity"
import { toPatch } from "@/_services/inputUtils"
import { loc } from "@/_services/localization"

// this is a generic quick search dropdown. Can search for an entity supported by EntitySearchModal
// e.g. at the time of this writing searchEntityName can be Person, Contract, Amendment, Asset, Invoice, Cashflow
class FormInputEntitySearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      notAllowed: false,
      showSearchModal: false,
      showQuickAddModal: false,
      showQuickAddPerson: false,
      displayValue: undefined,
      loadedRegistration: undefined,
    }
  }

  componentDidMount() {
    this.componentDidUpdate()
  }

  componentDidUpdate() {
    const { displayValue, loadedRegistration, loading } = this.state
    const { obj, field, searchEntityName, value } = this.props

    if (loading || !searchEntityName) return

    let registration
    if (field?.includes(".")) registration = get(obj, field)
    else if (obj) registration = obj[field]
    else if (value) registration = value // Used mainly in <Table/>

    if (!registration) {
      if (displayValue) this.setState({ displayValue: undefined })
    } else if (!isEqual(loadedRegistration, registration) || !displayValue) {
      this.getEntity(registration)
    }
  }

  getEntity = async registration => {
    let { displayValue, notAllowed } = this.state
    this.setState({ loading: true })

    const { searchEntityName, customSearch, multiple } = this.props
    const projection = "-_id,registration,name"

    let entity
    try {
      if (multiple && Array.isArray(registration)) {
        if (!displayValue) displayValue = {}

        const registrationsToQuery = [],
          entities = []
        registration
          .filter(it => !displayValue?.[it])
          .forEach(it => {
            const orga = getOrganizationsObj()[it]
            if (orga) entities.push(orga)
            else registrationsToQuery.push(it)
          })

        await Promise.all(
          chunk(registrationsToQuery).map(async chunk =>
            entities.push(...(await getEntities(searchEntityName, { registration: chunk, projection }).then(({ data }) => data))),
          ),
        )

        for (let i = 0; i < entities?.length; i++) {
          const entity = entities[i]
          if (entity) displayValue[entity.registration] = entity.name
        }
      } else {
        entity = getOrganizationsObj()[registration]
        if (!entity) {
          if (customSearch) entity = (await customSearch({ registration, projection }))?.[0]
          else entity = (await getEntities(searchEntityName, { registration, projection }).then(({ data }) => data))[0]
        }

        if (entity) {
          if (registration === "TENANT" || registration === entity.name) {
            displayValue = entity.name
          } else {
            if (entity.name) displayValue = `${entity.name} (${registration})`
            else displayValue = registration
          }
        } else {
          displayValue = registration
          notAllowed = true
        }
      }
    } finally {
      this.setState({
        entity,
        loading: false,
        loadedRegistration: registration,
        displayValue,
        notAllowed,
      })
    }
  }

  quickSearch = async search => {
    const { filters, query, searchEntityName, customQuickSearch, defaultOptions, entityField } = this.props
    const params = query || filters

    let entities
    if (customQuickSearch) {
      entities = await customQuickSearch(search, params)
    } else {
      if (!search && defaultOptions) {
        const { data } = await getEntities(searchEntityName, params)
        entities = data
      } else {
        entities = await searchEntities(searchEntityName, search, params)
      }
    }

    return entities.map(entity => {
      const label = []
      if (entityField) {
        if (entity[entityField]) label.push(entity[entityField])
      } else {
        if (entity.name) label.push(entity.name)
        if (entity.registration && entity.registration !== "TENANT") label.push(entity.name ? ` (${entity.registration})` : entity.registration)
      }

      return { value: entity[entityField || "registration"], label: label.join("") }
    })
  }

  handleSearchModalClose = async selected => {
    let { displayValue } = this.state
    const { onSetState, field, multiple, obj, searchEntityName, searchEntityMultiple, includeSearchEntityInPatch, entityField } = this.props

    let showQuickAddModal
    let showQuickAddPerson
    if (selected === "add") {
      if (["Person", "Prospect"].includes(searchEntityName)) showQuickAddPerson = true
      else showQuickAddModal = true
    }

    // selected is an array of entities
    else if (Array.isArray(selected) && searchEntityMultiple) {
      displayValue = {}

      const entityRegistrations = []
      for (let entity of selected) {
        entityRegistrations.push(entity[entityField || "registration"])
        if (!entityField) displayValue[entity.registration] = getEntityDisplay(entity)
      }

      let patch = {}
      if (field?.includes(".")) patch = toPatch(obj, field, entityRegistrations)
      else set(patch, field, entityRegistrations)

      onSetState(patch)
    }

    // selected is an entity object
    else if (selected?.registration || (entityField && selected?.[entityField])) {
      let value
      if (multiple) {
        value = get(obj, field)
        if (!Array.isArray(value)) value = (value || "").split(",").filter(v => v)
        value.push(selected[entityField || "registration"])

        if (!displayValue) displayValue = {}
        displayValue[selected[entityField || "registration"]] = selected[entityField || "name"] || selected.registration
      } else {
        displayValue = null // Reset displayValue when changing entity to re-fetch it in componentDidUpdate()
        value = selected[entityField || "registration"]
      }

      let patch = { [field]: value }
      if (!multiple && includeSearchEntityInPatch && searchEntityName) {
        const entity = await getEntity(searchEntityName, selected.registration)
        if (entity) {
          const entityField = `${field.replace("Registration", "")}` // personRegistration => person
          patch[entityField] = entity
        }
      }

      // Support nested fields
      if (field?.includes(".")) patch = toPatch(obj, Object.keys(patch), Object.values(patch))
      onSetState(patch)
    }

    this.setState({ displayValue, showSearchModal: false, showQuickAddPerson, showQuickAddModal, loadedRegistration: selected?.registration })
  }

  handleEntitySearchSetState = async patch => {
    if (!patch) return

    const { obj, field, onSetState, includeSearchEntityInPatch, searchEntityName } = this.props

    const entityRegistration = patch[field]
    if (entityRegistration) {
      patch = { [field]: entityRegistration }

      if (includeSearchEntityInPatch && searchEntityName) {
        const entity = await getEntity(searchEntityName, entityRegistration)
        if (entity) {
          const entityField = `${field.replace("Registration", "")}` // personRegistration => person
          patch[entityField] = entity
        }
      }
    }

    // Support nested fields
    if (field?.includes(".")) patch = toPatch(obj, Object.keys(patch), Object.values(patch))

    onSetState(patch)
  }

  quickSearchSelectOptions = () => async search => this.quickSearch(search)

  render() {
    const { showSearchModal, showQuickAddPerson, showQuickAddModal, displayValue, notAllowed } = this.state
    let {
      obj,
      field,
      query,
      filters,
      columns,
      multiple,
      actionHidden,
      colProps,
      placeholder,
      customSearch,
      customQuickSearch,
      customGetEntities,
      disabled,
      readOnly,
      modelPath,
      onSetState,
      searchEntityName,
      searchEntityMultiple,
      label,
      advancedSearch,
      showAdd,
      detailed,
      DebugOverlay,
      value,
      params,
    } = this.props

    const isProspect = searchEntityName === "Prospect"

    const registration = obj?.[field]
    const SearchModal = () => (
      <EntitySearchModal
        query={query}
        params={params}
        showAdd={showAdd}
        filters={filters}
        columns={columns}
        customSearch={customSearch}
        entityName={searchEntityName}
        multiple={searchEntityMultiple}
        advancedSearch={advancedSearch}
        customQuickSearch={customQuickSearch}
        customGetEntities={customGetEntities}
        onClose={this.handleSearchModalClose}
        selectedEntities={searchEntityMultiple && obj[field]}
      />
    )

    placeholder = loc(placeholder || "Select") + "..."

    if (multiple) {
      const _label = label || labelFromName(field)
      const formGroup = (
        <>
          <FormGroup>
            <ControlLabel>
              {_label}
              <DebugOverlay />
            </ControlLabel>
            <InputGroup>
              <AsyncSelect
                id={_label}
                isMulti
                closeOnSelect={false}
                className="select-group"
                classNamePrefix="select"
                isDisabled={disabled || readOnly}
                placeholder={placeholder}
                onChange={newValues => {
                  let statePatch = { [field]: newValues.map(v => v.value) }
                  if (field.includes(".")) statePatch = toPatch(obj, Object.keys(statePatch), Object.values(statePatch))
                  onSetState(statePatch)
                }}
                loadOptions={debounce((query, callback) => {
                  this.quickSearchSelectOptions()(query).then(resp => callback(resp))
                }, 300)}
                components={getSelectComponents({ isClearable: true, showCopy: true, multiple })}
                value={(() => {
                  const values = get(obj, field)
                  return Array.isArray(values) && values?.map(it => ({ value: it, label: displayValue?.[it] || it }))
                })()}
                noOptionsMessage={() => loc("No data")}
                loadingMessage={() => loc("Loading")}
                aria-labelledby={_label}
              />
              {!readOnly && !disabled && !actionHidden && (
                <InputGroup.Addon className="c-pointer" onClick={() => this.setState({ showSearchModal: true })}>
                  <i className="icn-search icn-xs" />
                </InputGroup.Addon>
              )}
            </InputGroup>
          </FormGroup>
          {showSearchModal && <SearchModal />}
        </>
      )

      if (colProps) return <Col {...colProps}>{formGroup}</Col>
      return formGroup
    }

    return (
      <>
        <FormInput
          linkTo={!notAllowed && registration && `${getEntityClientRoute(searchEntityName)}/${registration}`}
          modelPath={modelPath}
          select={this.quickSearch}
          // Setting defaultOptions to [] is important
          // so that the lib react-async does not fire the select function until the user starts typing something
          // because it is placed in the render section of FormInput
          defaultOptions={[]}
          action={!disabled && !actionHidden && (() => this.setState({ showSearchModal: true }))}
          actionIcon={!disabled && !actionHidden && "icn-search icn-xs"}
          actionClassName={!disabled && !actionHidden && "c-pointer"}
          {...this.props}
          placeholder={placeholder} // TODO : loc() twice!
          value={displayValue ? displayValue : value}
          onSetState={this.handleEntitySearchSetState}
          noLodash // Disable lodash to handle nested fields in handleEntitySearchSetState() because in some cases we need to update the entityRegistration and the entity (ex: "persons")
        />

        {showSearchModal && <SearchModal />}
        {showQuickAddPerson && (
          <PersonQuickAddModal
            type={query?.type}
            roles={query?.roles}
            role={query?.role}
            onClose={this.handleSearchModalClose}
            entityName={searchEntityName}
            usePersonLayout={!isProspect}
            useProspectLayout={isProspect}
            isUser={false}
            detailed={detailed}
            query={query || filters}
          />
        )}
        {showQuickAddModal && (
          <EntityQuickAddModal
            rows={showAdd?.rows}
            title={showAdd?.title}
            entityType={query?.type}
            entityName={searchEntityName}
            onClose={this.handleSearchModalClose}
            saveButtonLabel={showAdd?.saveButtonLabel}
            cancelButtonLabel={showAdd?.cancelButtonLabel}
          />
        )}
      </>
    )
  }
}

export default FormInputEntitySearch
