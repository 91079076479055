import { formatAxiosError } from "@/_services/axios"
import { loc } from "@/_services/localization"
import { getNoDecoration } from "@/_services/theming"
import { isRtl } from "@/_services/userConfiguration"

const state = {
  notificationComponent: null,
  options: null,
  notifications: [],
}

function register(notificationComponent, options) {
  state.notificationComponent = notificationComponent
  state.options = options
}

function unregister() {
  state.notificationComponent = null
}

function handleNotification({ message, level = "success", position = "br", autoDismiss, action }) {
  if (state.notificationComponent) {
    const notification = {
      date: new Date(),
      title: "",
      message,
      level,
      position,
      autoDismiss, // time in seconds
      action,
      dismissible: action ? "none" : "click",
    }
    state.notifications.push(notification)
    state.notificationComponent.addNotification(notification)
  }
}

function _addNotification({ message, level = "success", position, action, autoDismiss }) {
  if (!position) position = getNoDecoration() ? "tr" : "br"
  message = loc(message)

  // hack because the lib we use is not easily customisable with CSS
  // we need to change this lib one day
  if (isRtl()) {
    if (position === "bl") position = "tr"
    if (position === "br") position = "bl"
    if (position === "tr") position = "tl"
    if (position === "tl") position = "tr"
  }

  if (action && action.label) action.label = loc(action.label)
  handleNotification({ message, level, position, autoDismiss: autoDismiss ?? (action ? 0 : 5), action })
}

function addNotification() {
  if (typeof arguments[0] === "string") {
    _addNotification({ message: arguments[0], level: arguments[1], position: arguments[2], action: arguments[3], autoDismiss: arguments[4] })
    return
  }

  if (typeof arguments[0] === "object" && Object.keys(arguments[0]).length > 0) {
    const { message, level, position, action, autoDismiss } = arguments[0]
    _addNotification({ message, level, position, action, autoDismiss })
  }
}

function _addOops({ error, autoDismiss }) {
  if (error?.response?.status === 499) {
    const message = loc(error.response.data.message)
    addNotification({ message, level: "warning", autoDismiss })
    return
  }

  // this is for POST /bank-operation - would be better if it was sending a 499 instead of 409 => TODO
  if (error?.response?.status === 409 && error.response.data?.rejectionReason) {
    const message = loc(error.response.data.rejectionReason)
    addNotification({ message, level: "warning", autoDismiss })
    return
  }

  let message = loc("Oops, something went wrong")
  if (error && message) message += ": " + formatAxiosError(error)
  addNotification({ message, level: "error", autoDismiss })
  console.error(error)
}

function addOops(_error) {
  const error = typeof _error === "string" ? { message: loc(_error) } : _error
  const autoDismiss = 15

  if (Array.isArray(error?.response?.data?.errors)) {
    _addOops({ autoDismiss, error: { response: { data: error.response.data.errors.join("\n"), status: 400 } } })
    return
  }

  if (error?.response?.data && typeof error.response.data === "object" && error.response.data instanceof Blob) {
    // need to read asynchronously (e.g. cannot move that code in formatAxiosError())
    error.response.data.text().then(text => {
      try {
        const data = JSON.parse(text)
        _addOops({
          autoDismiss,
          error: {
            response: {
              data,
              status: data.status,
            },
          },
        })
      } catch (ignoredError) {
        _addOops({
          autoDismiss,
          error: {
            response: {
              data: text,
              status: error.response.status,
            },
          },
        })
      }
    })
    return
  }

  _addOops({ autoDismiss, error })
}

function getNotifications() {
  return state.notifications
}

export { addNotification, addOops, getNotifications, register as registerNotifications, unregister as unregisterNotifications }
