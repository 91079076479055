import { ROUND } from "./financeUtils.mjs"
import { currenciesSymbols, getLocaleParameters } from "./localeUtils.mjs"
import { replaceASCII } from "./stringUtils.mjs"

const ibanCountryConfig = {
  AD: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Andorra: ADkk BBBB BBBB CCCC CCCC CCCC
  AE: { format: [4, 4, 4, 4, 3], length: 23 }, // United Arab Emirates: AEkk BBBB BBBB CCCC CCCC CCC
  AT: { format: [4, 4, 4, 4, 4], length: 20 }, // Austria: ATkk BBBB BBBB CCCC CCCC
  AZ: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Azerbaijan: AZkk BBBB BBBB CCCC CCCC CCCC CCCC
  BA: { format: [4, 4, 4, 4, 4], length: 20 }, // Bosnia and Herzegovina: BAkk BBBS SSCC CCCC CCCC
  BE: { format: [4, 4, 4, 4], length: 16 }, // Belgium: BEkk BBBB CCCC CCCC
  BG: { format: [4, 4, 4, 4, 4, 2], length: 22 }, // Bulgaria: BGkk BBBB SSSS DDCC CCCC CC
  BH: { format: [4, 4, 4, 4, 4, 2], length: 22 }, // Bahrain: BHkk BBBB SSSS CCCC CCCC CC
  BR: { format: [4, 4, 4, 4, 4, 4, 5], length: 29 }, // Brazil: BRkk BBBB BBBB SSSS CCCC CCCC CCCC CC
  CD: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // Congo (Democratic Republic): CDkk BBBB BBBB CCCC CCCC CCCC CCC
  CH: { format: [4, 4, 4, 4, 5], length: 21 }, // Switzerland: CHkk BBBB BBBB CCCC CCCC C
  CR: { format: [4, 4, 4, 4, 5], length: 21 }, // Costa Rica: CRkk BBBB BBBB CCCC CCCC C
  CY: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Cyprus: CYkk BBBB BBBB CCCC CCCC CCCC CCCC
  CZ: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Czech Republic: CZkk BBBB SSSS SSCC CCCC CCCC
  DE: { format: [4, 4, 4, 4, 4, 2], length: 22 }, // Germany: DEkk BBBB BBBB CCCC CCCC CC
  DK: { format: [4, 4, 4, 4, 2], length: 18 }, // Denmark: DKkk BBBB CCCC CCCC CC
  DO: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Dominican Republic: DOkk BBBB BBBB CCCC CCCC CCCC CCC
  EE: { format: [4, 4, 4, 4, 4], length: 20 }, // Estonia: EEkk BBSS CCCC CCCC CCC
  ES: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Spain: ESkk BBBB GGGG CCCC CCCC CCC
  FI: { format: [4, 4, 4, 4, 2], length: 18 }, // Finland: FIkk BBBB BBCC CCCC CC
  FO: { format: [4, 4, 4, 4, 2], length: 18 }, // Faroe Islands: FOkk BBBB CCCC CCCC CC
  FR: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // France: FRkk BBBB BBBB CCCC CCCC CCCC CCC
  GB: { format: [4, 4, 4, 4, 4, 2], length: 22 }, // United Kingdom: GBkk BBBB SSSS SSCC CCCC CC
  GI: { format: [4, 4, 4, 4, 5], length: 23 }, // Gibraltar: GIkk BBBB XXXX XXXX XXXX XXX
  GL: { format: [4, 4, 4, 4, 2], length: 18 }, // Greenland: GLkk BBBB CCCC CCCC CC
  GQ: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // Equatorial Guinea: GQkk BBBB BBBB CCCC CCCC CCCC CCC
  GR: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // Greece: GRkk BBBB SSSS CCCC CCCC CCCC CCC
  GT: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Guatemala: GTkk AAAA BBBB CCCC CCCC CCCC CCCC
  GW: { format: [4, 4, 4, 4, 4, 5], length: 25 }, // Guinea-Bissau: GWkk BBBB BBBB CCCC CCCC CCCC
  HR: { format: [4, 4, 4, 4, 5], length: 21 }, // Croatia: HRkk BBBB BBCC CCCC CCCC C
  HU: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Hungary: HUkk BBBB BBBB CCCC CCCC CCCC CCC
  IE: { format: [4, 4, 4, 4, 4, 2], length: 22 }, // Ireland: IEkk AAAA BBBB BBCC CCCC CC
  IL: { format: [4, 4, 4, 4, 3, 4], length: 23 }, // Israel: ILkk BBBB BBBB CCC CCC CCCC
  IS: { format: [4, 4, 4, 4, 4, 4, 2], length: 26 }, // Iceland: ISkk BBBB SSCC CCCC XXXX XX
  IT: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // Italy: ITkk XAAA AABB BBBB CCCC CCCC CCC
  JO: { format: [4, 4, 4, 4, 4, 4, 6], length: 30 }, // Jordan: JOkk BBBB NNNN CCCC CCCC CCCC CCC
  KW: { format: [4, 4, 4, 4, 4, 4, 6], length: 30 }, // Kuwait: KWkk BBBB NNNN CCCC CCCC CCCC CCC
  KZ: { format: [4, 4, 4, 4, 4], length: 20 }, // Kazakhstan: KZkk BBBB NNNN CCCC CCCC
  LB: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Lebanon: LBkk BBBB NNNN CCCC CCCC CCCC CCCC
  LI: { format: [4, 4, 4, 4, 5], length: 21 }, // Liechtenstein: LIkk BBBB BBBB CCCC CCCC C
  LT: { format: [4, 4, 4, 4, 4], length: 20 }, // Lithuania: LTkk BBBB BBBB CCCC CCCC
  LU: { format: [4, 4, 4, 4, 4], length: 20 }, // Luxembourg: LUkk BBBB CCCC CCCC CC
  LV: { format: [4, 4, 4, 4, 5], length: 21 }, // Latvia: LVkk BBBB CCCC CCCC CCCC
  MA: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Morocco: MAkk BBBB CCCC CCCC CCCC CCCC CCC
  MC: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // Monaco: MCkk BBBB BBBB CCCC CCCC CCCC CCC
  MD: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Moldova: MDkk BBCC CCCC CCCC CCCC CC
  ME: { format: [4, 4, 4, 4, 4], length: 22 }, // Montenegro: MEkk BBBB CCCC CCCC CCCC
  MK: { format: [4, 4, 4, 4, 3], length: 19 }, // North Macedonia: MKkk BBBB CCCC CCCC CC
  MR: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // Mauritania: MRkk BBBB BBBB CCCC CCCC CCCC CCC
  MT: { format: [4, 4, 4, 4, 4, 4, 6], length: 31 }, // Malta: MTkk BBBB SSSS CCCC CCCC CCCC CCC
  MU: { format: [4, 4, 4, 4, 4, 4, 6], length: 30 }, // Mauritius: MUkk BBBB BBSS CCCC CCCC CCCC CCC
  MZ: { format: [4, 4, 4, 4, 4, 5], length: 25 }, // Mozambique: MZkk BBBB BBBB CCCC CCCC CCCC
  NL: { format: [4, 4, 4, 2], length: 18 }, // Netherlands: NLkk BBBB CCCC CCCC CC
  NO: { format: [4, 4, 4, 2], length: 15 }, // Norway: NOkk BBBB CCCC CC
  PK: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Pakistan: PKkk BBBB CCCC CCCC CCCC CCCC
  PL: { format: [4, 4, 4, 4, 4, 4, 4], length: 28 }, // Poland: PLkk BBBB BBBB CCCC CCCC CCCC CCC
  PS: { format: [4, 4, 4, 4, 4, 4, 5], length: 29 }, // Palestine: PSkk BBBB XXXX XXXX XXXX XXXX XXX
  PT: { format: [4, 4, 4, 4, 4, 5], length: 25 }, // Portugal: PTkk BBBB BBBB CCCC CCCC CCCC
  QA: { format: [4, 4, 4, 4, 4, 4, 5], length: 29 }, // Qatar: QAkk BBBB NNNN CCCC CCCC CCCC CCCC C
  QX: { format: [4, 4, 4, 4, 4, 4, 7], length: 34 }, // Quatar Extended: QXkk BBBB XXXX XXXX XXXX XXXX XXXX XXX
  RO: { format: [4, 4, 4, 4, 5], length: 24 }, // Romania: ROkk BBBB CCCC CCCC CCCC CCCC
  RS: { format: [4, 4, 4, 4, 4], length: 22 }, // Serbia: RSkk BBBB CCCC CCCC CCCC
  SA: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Saudi Arabia: SAkk BBCC CCCC CCCC CCCC CCCC
  SE: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Sweden: SEkk BBBB CCCC CCCC CCCC CCCC
  SI: { format: [4, 4, 4, 4, 3], length: 19 }, // Slovenia: SIkk BBBB CCCC CCCC CCC
  SK: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Slovakia: SKkk BBBB SSSS SSCC CCCC CCCC
  SM: { format: [4, 4, 4, 4, 4, 4, 3], length: 27 }, // San Marino: SMkk XAAA AABB BBBB CCCC CCCC CCC
  TN: { format: [4, 4, 4, 4, 4, 4], length: 24 }, // Tunisia: TNkk BBBB BBBB CCCC CCCC CCCC
  TR: { format: [4, 4, 4, 4, 4, 4, 2], length: 26 }, // Turkey: TRkk BBBB BBCC CCCC CCCC CCCC
}

/**
 * @param {Number|String} input
 * @returns Returns 1 if the IBAN is valid. False if the IBAN's length is not as it should be (for CY the IBAN should be 28 chars long starting with CY). Any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIban(input) {
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "") // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== ibanCountryConfig[code[1]]?.length) return false

  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })

  // final check
  return mod97(digits) === 1
}

/**
 * @param {Number|String} input
 * @param {string} country
 * @returns Returns 1 if the RIB is valid. False if the RIB's length is not as it should be (for MA the RIB should be 24 chars long). Any other number (checksum) when the RIB is invalid (check digits do not match)
 * This function was developed for arrawaj therefore for Moroccan RIB only!
 * TODO: function to be evolved so that it is operational for French RIB for example
 */
function isValidRib(input, country) {
  if (!/^\d+$/.test(input)) return false
  if (country === "MA" && input.length !== 24) return false
  const accountNumber = input.substring(0, 22)
  const bankKey = input.substring(22)
  // eslint-disable-next-line no-undef
  const calculatedKey = String(BigInt(97) - ((BigInt(accountNumber) * BigInt(100)) % BigInt(97))).padStart(2, "0")
  return bankKey === calculatedKey
}

function mod97(string) {
  let checksum = string.slice(0, 2)
  let fragment

  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }

  return checksum
}

/**
 * @param {number} number
 * @param {string} locale
 * @returns Returns the number rounded to the number decimal places according to the locale, or 2 by default.
 */
function roundCurrency(number, locale) {
  if (!number) return number
  number = Number(number)
  const decimalPlace = getLocaleParameters(locale).decimalPlace
  const result = ROUND(number, decimalPlace)
  return result === 0 ? 0 : result // to avoid -0
}

/**
 * @param {string} number
 * @param {string} locale
 * @returns Returns the numeric part of the currency string.
 */
function unformatCurrency(number, locale) {
  if (!number || typeof number !== "string") return number
  number = replaceASCII(number).replace(/\s/g, "")

  const {
    delimiters: { thousands, decimal },
    currency: { symbol, code },
  } = getLocaleParameters(locale)

  // Currency
  number = number.replace(symbol, "").replace(code, "")

  if (decimal !== ".") {
    // handle special case : even for countries using "," as decimal separator, accept "." if and only if followed by 2 figures
    const s1 = number.substring(number.length - 3, number.length - 2)
    const s2 = number.substring(number.length - 2, number.length - 1)
    const s3 = number.substring(number.length - 1, number.length - 0)
    if (s1 === "." && s2 >= "0" && s2 <= "9" && s3 >= "0" && s3 <= "9") {
      let left = number.substring(0, number.length - 3)
      left = left.replace(/,/g, "")
      number = left + decimal + s2 + s3
    } else if (number.indexOf(".") < 0) {
      // there is no decimal part, but let's support 1,000,000 also
      const s0 = number.substring(number.length - 4, number.length - 3)
      const s1 = number.substring(number.length - 3, number.length - 2)
      const s2 = number.substring(number.length - 2, number.length - 1)
      const s3 = number.substring(number.length - 1, number.length - 0)
      if (s0 === "," && s1 >= "0" && s1 <= "9" && s2 >= "0" && s2 <= "9" && s3 >= "0" && s3 <= "9" && decimal != ",") {
        number = number.replace(/,/g, "")
      }
    }
  }

  // Thousand separators
  let decimals = ""
  if (number.indexOf(decimal) > 0) decimals = number.substr(number.indexOf(decimal), number.length)
  if (decimals) number = number.replace(decimals, "")
  if (number.includes(thousands)) number = number.replace(thousands === "." ? /\./g : new RegExp(thousands, "g"), "")

  // Decimal
  if (decimals) number = number + decimals
  number = number.replace(decimal, ".")

  return Number(number)
}

/**
 * @param {number} number
 * @param {string} locale
 * @param {object} options
 * @returns Returns the string currency representation of the number according to the locale. See [Intl.NumberFormat()](https://v8.dev/features/intl-numberformat) for details regarding the option field.
 */
function formatCurrency(number, locale, options) {
  if (!number && number !== 0) return number

  // this is a hack to make it simpler when we want no digits
  //TODO : bug on option mutation
  if (options === 0) options = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  else if (typeof options === "string") options = { currency: options }
  else if (!options) options = {}

  const hasStyleNone = options.style === "none"

  const localeParameters = getLocaleParameters(locale)
  if (!options?.currency) options.currency = localeParameters.currency.code
  options.style = hasStyleNone ? undefined : "currency"
  if (localeParameters.useGrouping) options.useGrouping = true
  if (options.maximumFractionDigits === undefined && options.minimumFractionDigits === undefined) {
    options.minimumFractionDigits = localeParameters.decimalPlace
    options.maximumFractionDigits = options.minimumFractionDigits
  }

  // avoid -0.00
  if (Object.is(Math.round(number * 10 ** (options.minimumFractionDigits || 2)), -0)) number = 0

  let formattedCurrency = new Intl.NumberFormat(locale, options).format(number)

  if (["fr-GN", "lt-LT", "fr-BE"].includes(locale)) {
    formattedCurrency = replaceASCII(formattedCurrency)
    if (hasStyleNone) formattedCurrency = formattedCurrency.replace(/ /g, ".")
    else formattedCurrency = formattedCurrency.replace(/[ ](?=.*[ ])/g, ".")
  }
  if (["en-LT", "en-BE"].includes(locale)) {
    formattedCurrency = formattedCurrency.replace(new RegExp(/,/g), "#").replace(new RegExp(/\./), ",").replace(new RegExp(/#/g), ".")
  }
  if (locale === "mx-MX") {
    formattedCurrency = formattedCurrency.replace("MX", "")
  }
  return formattedCurrency
}

/**
 * WARNING : this function is obsolete and should not be used anymore.
 * Use formatCurrency instead.
 * @param {number} number
 * @param {string} currencyCodeOrLocale
 * @param {object} options
 * @returns Returns the string currency representation of the number according to the locale. See [Intl.NumberFormat()](https://v8.dev/features/intl-numberformat) for details regarding the option field.
 */
function getFormattedCurrency(number, currencyCodeOrLocale, options = {}) {
  if (currencyCodeOrLocale?.includes("-")) {
    return formatCurrency(number, currencyCodeOrLocale, options)
  }

  options.currency = currencyCodeOrLocale //TODO : bug on option mutation
  return formatCurrency(number, undefined, options)
}

/**
 * @param {string} locale In the form "fr-FR"
 * @param {string} [currencyCode] For instance "EUR". If provided, the function will first use it to look up and fallback to the locale if there is no match.
 * @param {object} [options]
 * @param {string} [options.currencyDisplay] If value is "code" then return the provided currency code instead of a symbol from the list.
 * @returns Returns the symbol of the currency, for instance "€".
 */
function getCurrencySymbol(locale, currencyCode, options = {}) {
  const codeDisplay = options.currencyDisplay === "code"
  const { symbol: localeCurrencySymbol, code } = getLocaleParameters(locale).currency || {}
  const currencyFromCode = currenciesSymbols[currencyCode]
  return currencyCode
    ? codeDisplay
      ? currencyFromCode?.code || code
      : currencyFromCode?.symbol || localeCurrencySymbol
    : codeDisplay
    ? code
    : localeCurrencySymbol
}

function coerceStringToJsNumber(string, locale) {
  if (typeof string !== "string" || string === "") return string
  const { delimiters } = getLocaleParameters(locale)
  const localeDecimalDelimiter = delimiters.decimal
  const localeThousandsDelimiter = delimiters.thousands
  const currencySymbol = getCurrencySymbol(locale)
  const replaceRegex = new RegExp(
    `\\s|%${currencySymbol ? `|${currencySymbol}` : ""}${localeThousandsDelimiter ? `|\\${localeThousandsDelimiter}` : ""}`,
    "g",
  )
  const lintedString = string.replace(replaceRegex, "").replace(localeDecimalDelimiter, ".")
  return isNaN(lintedString) ? string : parseFloat(lintedString)
}

function formatIban(iban) {
  const unformattedIban = unformatIban(iban)
  // Extract the country code from the IBAN
  const countryCode = unformattedIban.slice(0, 2).toUpperCase()

  // Get the format for the specific country
  const ibanConfig = ibanCountryConfig[countryCode]

  // Check if the format and length are valid
  if (!ibanConfig || ibanConfig.length !== unformattedIban.length) return iban

  let formattedIban = ""
  let index = 0

  // Apply the format
  ibanConfig.format.forEach(groupSize => {
    formattedIban += " " + unformattedIban.slice(index, index + groupSize)
    index += groupSize
  })

  return formattedIban.trim()
}

function unformatIban(iban) {
  return iban.replace(/\s/g, "")
}

export {
  coerceStringToJsNumber,
  formatCurrency,
  formatIban,
  getCurrencySymbol,
  getFormattedCurrency,
  ibanCountryConfig,
  isValidIban,
  isValidRib,
  roundCurrency,
  unformatCurrency,
  unformatIban,
}
