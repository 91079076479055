import EntitySearchModal from "@/_components/EntitySearchModal"
import PersonQuickAddModal from "@/person/PersonQuickAddModal"
import React from "react"

class PersonDescendantsModals extends React.Component {
  render() {
    const {
      showPersonQuickAddModal,
      quickAddRelations,
      agreement,
      entityName,
      cardName,
      entityServerRoute,
      showPersonsSearchModal,
      handlePersonsQuickAddModalClose,
      usePersonLayout,
      advancedSearch,
      customSearch,
      customGetEntities,
      customQuickSearch,
      detailed,
      query = {},
    } = this.props

    if (showPersonQuickAddModal) {
      const { descendant } = showPersonQuickAddModal[4] || {}
      const clickedPersonRegistration = showPersonQuickAddModal[2]
      const kind = showPersonQuickAddModal[3]
      const qar = kind && quickAddRelations.find(it => it.code === kind)
      const type = qar?.quickAddType
      const roles = qar?.quickAddRoles
      const registrationType = qar?.quickAddRegistrationType
      const profiles = qar?.quickAddProfiles
      const setOrganization = qar?.quickAddOrganization
      const partnerRegistration = clickedPersonRegistration
      const trigram = agreement?.trigram || clickedPersonRegistration?.substring(0, 3)
      const isUser = qar?.isUser
      return (
        <PersonQuickAddModal
          kind={kind}
          type={type}
          roles={roles}
          registrationType={registrationType}
          profiles={profiles}
          setOrganization={setOrganization}
          partnerRegistration={partnerRegistration}
          trigram={trigram}
          isUser={isUser}
          onClose={addedPerson => handlePersonsQuickAddModalClose("showPersonQuickAddModal", addedPerson)}
          entityName={entityName}
          usePersonLayout={usePersonLayout}
          cardName={cardName}
          entityServerRoute={entityServerRoute}
          descendantRelation={descendant?.relation}
          detailed={detailed}
        />
      )
    }

    if (showPersonsSearchModal) {
      const quickAddRelation = (showPersonsSearchModal[3] && quickAddRelations.find(it => it.code === showPersonsSearchModal[3])) || {}
      // The type and the diagram of person should always quickAddType and should not depend on direction/isDescendant
      const type = quickAddRelation.quickAddType
      const trigram = quickAddRelation.quickAddTrigram
      const roles = quickAddRelation.quickAddRoles?.map(quickAddRole => quickAddRole?.role)
      const descendantIndex = showPersonsSearchModal[1]

      return (
        <EntitySearchModal
          entityName={entityName}
          query={{ ...query, type, trigram, role: roles }}
          advancedSearch={advancedSearch}
          customSearch={customSearch && (query => customSearch(query, { descendantIndex }))}
          customGetEntities={customGetEntities && (query => customGetEntities(query, { descendantIndex }))}
          customQuickSearch={customQuickSearch && ((search, query) => customQuickSearch(search, query, { descendantIndex }))}
          onClose={addedPerson => handlePersonsQuickAddModalClose("showPersonsSearchModal", addedPerson)}
        />
      )
    }

    return null
  }
}

export default PersonDescendantsModals
