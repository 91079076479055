import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import EntitySearchModal from "@/_components/EntitySearchModal"
import FormInput from "@/_components/FormInput"
import { findAddress } from "@/_services/address"
import { isEmpty, syncFieldProps } from "@/_services/inputUtils"
import { getItem, getLabel, getList, getValues } from "@/_services/lists"
import { loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getPerson } from "@/_services/personUtils"
import { runScriptLocally } from "@/_services/scripts"
import { getOptions } from "@/_services/userConfiguration"
import {
  checkRegistrationFormat,
  copyToClipboard,
  DEFAULT_DEBOUNCE,
  getEntities,
  handleAccessibleOnKeyDown,
  pseudoSelectOptionClassName,
} from "@/_services/utils"
import PersonRegistrationModal from "@/person/PersonRegistrationModal"
import axios from "axios"
import { getFieldProps, setError, setTouched, toQueryString } from "basikon-common-utils"
import debounce from "lodash.debounce"
import React, { createRef } from "react"
import { Col, ControlLabel, FormControl, FormGroup, InputGroup, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

class PersonRegistrationComponent extends React.Component {
  constructor(props) {
    super(props)

    const { personType } = this.props

    this.state = {
      automaticTypes: ["AUT", /*"IND",*/ "COM", "PTN", "PRO"], // legacy hard-coded list, should be handled by configuration only => do not add more
      externalTypes: ["SIR", "EVA", "GBR", "CHE", "CIF", "NIF", "ITA", "DEU"], // legacy hard-coded list, should be handled by configuration only => do not add more
      showSearchModal: false,
      registrationTypeValues: [],
      registrationNumber: null,
      personRegistrationListName:
        personType === "I"
          ? "individualRegistrationType"
          : personType === "E"
          ? "individualRegistrationType"
          : personType === "P"
          ? "professionalRegistrationType"
          : personType === "O"
          ? "organizationRegistrationType"
          : "companyRegistrationType",
      loading: false,
      personUserConfig: {},
      searchResults: [],
      selectedOptionIndex: null,
      showSearchResults: false,
    }

    this.personRegistrationRef = createRef()
  }

  componentDidMount() {
    const { person, mandatory, isProspect, onSetState, personType } = this.props
    const personUserConfig = getOptions(isProspect ? "prospect" : "person") || {}
    this.setState({ personUserConfig })
    getList("activityCode")

    this.getRegistrationTypes()

    const forcedDisplayType = this.getForceDisplayType(person, personType, personUserConfig)
    if (forcedDisplayType && !person.registration && person.registrations?.length)
      onSetState({ registrations: [{ type: forcedDisplayType, number: person.registrations[0].number }] })

    syncFieldProps({ obj: person, field: "registration", mandatory })
  }

  componentDidUpdate(prevProps) {
    const { registrationNumber } = this.state
    const { person, mandatory, personType, onSetState } = this.props
    if (prevProps.personType !== personType) {
      this.getRegistrationTypes()
      const forcedDisplayType = this.getForceDisplayType(person, personType)
      if (forcedDisplayType && !person.registration && person.registrations?.length) {
        onSetState({ registrations: [{ type: forcedDisplayType, number: person.registrations[0].number }] })
      }
    }

    if (
      registrationNumber &&
      ((prevProps.person?.registration && !person.registration) || (prevProps.person?.registrations && !person.registrations))
    ) {
      this.setState({ registrationNumber: null })
    }

    syncFieldProps({ obj: person, field: "registration", mandatory: person?.registrations?.[0]?.number ? false : mandatory })
  }

  componentWillUnmount() {
    const { person } = this.props
    syncFieldProps({ obj: person, field: "registration", mandatory: false })
  }

  getRegistrationTypes() {
    const { personType } = this.props
    const personRegistrationListName =
      personType === "I"
        ? "individualRegistrationType"
        : personType === "E"
        ? "individualRegistrationType"
        : personType === "P"
        ? "professionalRegistrationType"
        : personType === "O"
        ? "organizationRegistrationType"
        : "companyRegistrationType"
    const patch = { personRegistrationListName }
    const registrationTypeValues = getList(personRegistrationListName, results => this.setState({ registrationTypeValues: results.values }))?.values
    if (registrationTypeValues) patch.registrationTypeValues = registrationTypeValues
    this.setState(patch)
  }

  isAutomaticRegistrationType = registrationType => {
    const { automaticTypes } = this.state
    return automaticTypes.includes(registrationType) || this.getPersonRegistrationItem(registrationType)?.automatic
  }

  getExternalRegistrationType = registrationType => {
    if (!registrationType) return
    const personRegistrationItem = this.getPersonRegistrationItem(registrationType)
    if (personRegistrationItem?.externalSearch === false) return
    const externalRegistrationType = personRegistrationItem?.externalPivot || this.state.externalTypes.includes(registrationType)
    if (this.props.fromProspectOrPersonPage && ["PER", "PRS", "PER/PRS", "PRS/PER"].includes(externalRegistrationType)) return
    if (["PER/PRS", "PRS/PER"].includes(externalRegistrationType)) {
      if (this.props.isProspect) return "PRS"
      else return "PER"
    }
    return externalRegistrationType
  }

  getLoadOptions = registrationType => {
    const { registration = {} } = this.state.personUserConfig
    const {
      person,
      showCeasedCompanies = true,
      displayFirstRegistrationsInPriority = registration.displayFirstRegistrationsInPriority,
      withDetails = true,
      query,
    } = this.props

    let loadOptionType = this.getExternalRegistrationType(registrationType)
    if (loadOptionType && typeof loadOptionType !== "string") loadOptionType = registrationType
    if (loadOptionType) {
      const queryString = toQueryString({ administrativeStatus: !showCeasedCompanies ? "ACTIVE" : undefined })
      return async value =>
        value &&
        (
          await axios.post(`/api/external/pivot/person-registration/${loadOptionType}${queryString}`, {
            value,
            personType: person?.type,
            displayFirstRegistrationsInPriority,
            withDetails,
            ...query,
          })
        ).data
    }
  }

  getSelectedIdData = async (registrationType, selectedId) => {
    const { person, withDetails = true } = this.props
    return this.getExternalRegistrationType(registrationType) && this.getPersonRegistrationItem(registrationType)?.externalPivotFormatSelected
      ? (await axios.post(`/api/external/pivot/person-registration/${registrationType}`, { selectedId, personType: person?.type, withDetails })).data
      : {}
  }

  getForceDisplayType = (person, personType, personUserConfig) => {
    const { forceIndividualDisplayType, forceProfessionalDisplayType, forceOrganizationDisplayType, forceCompanyDisplayType } = this.props
    const { registration = {} } = personUserConfig || this.state.personUserConfig
    return personType === "I"
      ? person?.props_registration?.forceIndividualDisplayType || forceIndividualDisplayType || registration.forceIndividualDisplayType
      : personType === "E"
      ? person?.props_registration?.forceIndividualDisplayType || forceIndividualDisplayType || registration.forceIndividualDisplayType
      : personType === "P"
      ? person?.props_registration?.forceProfessionalDisplayType || forceProfessionalDisplayType || registration.forceProfessionalDisplayType
      : personType === "O"
      ? person?.props_registration?.forceOrganizationDisplayType || forceOrganizationDisplayType || registration.forceOrganizationDisplayType
      : person?.props_registration?.forceCompanyDisplayType || forceCompanyDisplayType || registration.forceCompanyDisplayType
  }

  getPersonRegistrationItem = registrationType => {
    const { personRegistrationListName } = this.state
    return getItem(personRegistrationListName, registrationType)
  }

  getPersonRegistrationItemLabel = registrationType => {
    const { personRegistrationListName } = this.state
    return getLabel(personRegistrationListName, registrationType)
  }

  getPersonRegistrationRichValues = () => {
    const { personRegistrationTypes } = this.props
    if (personRegistrationTypes && Array.isArray(personRegistrationTypes) && personRegistrationTypes.length > 0) {
      return personRegistrationTypes
    }
    const { personRegistrationListName } = this.state
    return getValues(personRegistrationListName)
      .filter(it => it.showInRegistrationsModal !== false) // !== false to be true when undefined
      .map(it => ({ value: it.value, label: it.label + " (" + it.value + ")" }))
  }

  handleRegistrationsModalClose = async patch => {
    if (patch) {
      const { registrations } = patch
      const mainRegistration = registrations?.[0]
      if (mainRegistration) {
        await this.handleAsyncSelectionChange({ value: mainRegistration.number, type: mainRegistration.type, registrations })
      }
    }
    this.setState({ showRegistrationsModal: false })
  }

  handleCloseConfirmation = async confirm => {
    const { showConfirmationModal } = this.state
    if (confirm && showConfirmationModal) {
      this.setState({ showConfirmationModal: { ...showConfirmationModal, loading: true } })
      await this.handleAsyncSelectionChange(showConfirmationModal)
    }
    this.setState({ showConfirmationModal: null })
  }

  handleSelectionConfirmation = async (args, event) => {
    const { showRegistrationConfirmModal } = this.props
    if (showRegistrationConfirmModal) {
      this.setState({ showConfirmationModal: args })
    } else {
      event.preventDefault()
      if (args) this.handleAsyncSelectionChange(args)
      // we update the key to reload the component and we set callback to hide results
      this.setState({ key: Math.random() }, () => this.setState({ showSearchResults: false }))
    }
  }

  lookUpRegistrationNumber = async ({ registration: argRegistration, withUpdate = true }) => {
    // If the registration has numbers and spaces only, for better UX we auto remove the spaces
    const registration = argRegistration?.match(/^[0-9 ]+$/) ? argRegistration.replaceAll(/ /g, "") : argRegistration

    const registrationType = this.getRegistrationType()
    if (withUpdate) {
      this.setState({ registrationNumber: registration })
      const { onSetState, person } = this.props
      const { registrations = [] } = person || {}
      const patch = {}
      patch.registrations = registrations.find(it => it.type === registrationType)
        ? registrations.map(it => ({ ...it, number: it.type === registrationType ? registration : it.number }))
        : [...registrations, { type: registrationType, number: registration }]
      if (this.getPersonRegistrationItem(registrationType)?.mainRegistration !== false)
        patch.registration = registration ? registrationType + registration : null

      onSetState(patch)
    }
    if (!registration) return

    const loadOptions = this.getLoadOptions(registrationType)
    try {
      this.setState({ loading: true })
      const searchResults = await loadOptions(registration)
      // This technique is to avoid showing the results if the user has not kept focus on the search input.
      // It avoids keeping them open for nothing, polluting the screen.
      const showSearchResults = this.personRegistrationRef.current === document.activeElement
      this.setState({ searchResults, showSearchResults, loading: false })
    } catch (error) {
      addOops(error)
      this.setState({ loading: false })
    }
  }

  handleAsyncSelectionChange = async args => {
    const { registration = {} } = this.state.personUserConfig
    const {
      onSetState,
      personType,
      allowDuplicates,
      obj,
      isProspect,
      displayFirstRegistrationsInPriority = registration.displayFirstRegistrationsInPriority,
      person,
      language,
      country,
    } = this.props
    const { registrations: prevRegistrations = [] } = person || {}

    if (!args) {
      // Warning: breaking UX change !
      // User has clicked on the cross icon of the input:
      // we delete the registration number that was present and only that.
      // The other person data already filled stay in place.
      // To remove the person data in one go, user has to click on the cross icon of the person tab (from PersonsComponent).
      const registrationType = this.getRegistrationType()
      const value = prevRegistrations.find(it => it.type === registrationType)?.number
      const isMainRegistration =
        person.registration && person.registration.substring(0, 3) === registrationType && person.registration.substring(3) === value
      const patch = { registrations: prevRegistrations.map(it => ({ ...it, number: it.type === registrationType ? null : it.number })) }
      if (isMainRegistration || prevRegistrations.length === 1) {
        patch.registration = null
      }
      this.setState({ registrationNumber: null })
      setError(patch, "registration", null)
      onSetState(patch)
      return
    }

    // There is also the label field returned, not used for now.
    // The format of the args is determined by the getLoadOptions response.
    // The modal data is formatted to match this signature in handleRegistrationsModalClose
    let { data = {}, value, registrations, type } = args

    // The registration type can be either provided by the new selected option
    // or be the default one when mounting the component without changing it.
    const registrationType = type || data.type || this.getRegistrationType()

    this.setState({ loading: true })

    try {
      if (data.id && !data.hasFetchedData) {
        const { data: selectedIdData, value: selectedIdValue } = await this.getSelectedIdData(registrationType, data.id)
        if (selectedIdData) Object.assign(data, selectedIdData)
        if (selectedIdValue) value = selectedIdValue
      }

      const patch = {}
      for (const key of Object.keys(data)) patch[key] = data[key]
      if (registrations) patch.registrations ||= registrations
      // !== false to be true if undefined
      const isMainRegistration = this.getPersonRegistrationItem(registrationType)?.mainRegistration !== false

      if (!patch.registration && isMainRegistration) patch.registration = value ? registrationType + value : null

      // We look for the same person in db and merge it to avoid creating duplicates.
      // Note that registration is not filled for automatic types.
      const mainRegistration = patch.registration
      let existingPerson =
        !allowDuplicates && mainRegistration
          ? (
              await getEntities(isProspect ? "prospect" : "person", {
                registration: mainRegistration,
                projection: "-_id,registration",
              }).then(({ data }) => data)
            )[0]
          : false
      if (existingPerson) {
        existingPerson = await getPerson(mainRegistration, null, isProspect) // Fetch full person
        if (person.registration && "registrations" in patch) existingPerson.registrations = patch.registrations
        Object.assign(patch, existingPerson)
      } else {
        // Person was not found, so it is going to be created
        // (only if patch contains more than just id, otherwise it means we are just changing the value of the registration input)
        // or if we're only patching the registrations array
        // We make sure we are not changing data from an existing person by forcing the _id
        if (!((Object.keys(patch).length === 1 && patch.id) || registrations)) patch._id = undefined
        // The type here is of the registration, it would override the current selected person type for new persons.
        // For existing persons the object assign instruction above overrides it.
        patch.type = personType
        if (!patch.registrations) {
          const newRegistrationNumber = patch.id || patch.registration
          const newRegistration = { type: registrationType, number: newRegistrationNumber }
          if (isMainRegistration || !prevRegistrations) patch.registrations = [newRegistration]
          else {
            patch.registrations = prevRegistrations.map(it => ({ ...it, number: it.type === registrationType ? newRegistrationNumber : it.number }))
            if (!prevRegistrations.find(it => it.type === registrationType)) patch.registrations.push(newRegistration)
          }
        }
      }

      if (patch.addresses?.[0]?.addressLine) {
        try {
          const address = await findAddress({ search: patch.addresses[0].addressLine, language, country })
          if (address) {
            // if "completeWithProviderAdd```ress" => complete pivot person address with the address provider details
            if (getOptions("completeWithProviderAddress")) {
              for (let field in address) {
                if (address[field] && !patch.addresses[0][field]) {
                  patch.addresses[0][field] = address[field]
                }
              }
            }
            // otherwise merge pivot person address with the address provider details
            else {
              patch.addresses[0] = { ...patch.addresses[0], ...address }
            }
          }
        } catch (error) {} // eslint-disable-line
      }

      // this code should be moved out
      if ("fiscalCode" in patch) {
        const siren = patch.fiscalCode.split(" ")[1]

        if (patch.registrations) {
          const indexSIR = patch.registrations.findIndex(p => p.type === "SIR")
          if (indexSIR === -1) patch.registrations.push({ type: "SIR", number: siren })
          else patch.registrations[indexSIR].number = siren

          const indexEVA = patch.registrations.findIndex(p => p.type === "EVA")
          if (indexEVA === -1) patch.registrations.push({ type: "EVA", number: patch.fiscalCode })
          else patch.registrations[indexEVA].number = patch.fiscalCode
        } else {
          patch.registrations = [
            { type: "SIR", number: siren },
            { type: "EVA", number: patch.fiscalCode },
          ]
        }
      }

      // this code should be moved out
      if ("siret" in patch) {
        patch.registrations = patch.registrations || []
        const indexSRT = patch.registrations.findIndex(p => p.type === "SRT")
        if (indexSRT === -1) patch.registrations.push({ type: "SRT", number: patch.siret })
        else patch.registrations[indexSRT].number = patch.siret
      }

      const item = this.getPersonRegistrationItem(registrationType)
      if (patch.activityCode && item?.activityCodeRemoveDots) {
        patch.activityCode = patch.activityCode.replace(".", "")
      }

      if (this.isAutomaticRegistrationType(registrationType)) setError(patch, "registration", null)
      else if (value) {
        const error = await this.validateRegistration(registrationType, value, registrations)
        setError(patch, "registration", error)
      }

      if (obj?.props_registration) {
        const forceDisplayType = this.getForceDisplayType(obj, personType)
        // The displayed registration is still the first one in the registrations array.
        if (forceDisplayType) patch.registration = "AUT"
      }

      onSetState(patch)
      this.setState({ registrationNumber: !displayFirstRegistrationsInPriority ? value : null })
    } finally {
      this.setState({ loading: false, showSearchResults: false })
    }
  }

  handleKeyDown = event => {
    const pressedKey = event?.key?.toLowerCase()
    const isArrowDown = pressedKey === "arrowdown"
    const isArrowUp = pressedKey === "arrowup"
    if ((isArrowUp || isArrowDown) && event?.target?.value?.length > 0) {
      this.handleNavigate({ event, isArrowUp, isArrowDown })
    }
    if (pressedKey === "enter") {
      this.handleSelection(event)
    }
  }

  handleNavigate = ({ event, isArrowUp, isArrowDown }) => {
    event.preventDefault()
    const { selectedOptionIndex, searchResults, showSearchResults } = this.state
    const selectionIndex = selectedOptionIndex ?? -1
    const activeIndex = (selectionIndex + (isArrowDown ? 1 : searchResults.length - 1)) % searchResults.length

    if (!showSearchResults) this.setState({ showSearchResults: true })

    if (isArrowUp || isArrowDown) {
      const options = document.getElementsByClassName(pseudoSelectOptionClassName)
      options[activeIndex]?.scrollIntoView({ block: "nearest" })
    }

    this.setState({ selectedOptionIndex: activeIndex })
  }

  handleSelection = event => {
    const { searchResults, selectedOptionIndex } = this.state
    const result = searchResults[selectedOptionIndex]
    this.handleSelectionConfirmation(result, event)
  }

  handleSearchModalClose = person => {
    const { onSetState } = this.props
    onSetState(person)
    this.setState({ showSearchModal: false })
  }

  handleSetRegistrationNumberSync = async value => {
    const { onSetState, person, personType } = this.props
    const registrationType = this.getRegistrationType()
    const registrationValidationError = await this.validateRegistration(registrationType, value)

    // this code is to sync the value written by the user with the values in the modal
    const { registrations = [] } = person || {}
    const nbOfRegistrations = registrations.length
    const number = registrationValidationError ? null : value
    if (nbOfRegistrations > 0) {
      for (let i = 0; i < nbOfRegistrations; i++) {
        const registrationItem = registrations[i]
        if (registrationItem.type === registrationType) {
          registrations[i].number = number
          break
        }
      }
    } else {
      registrations.push({ type: registrationType, number })
    }

    const forceDisplayType = this.getForceDisplayType(person, personType)

    const patch = {
      registrations,
    }

    // When forceDisplayType is active, let the server decide what will be the real registration
    // by not passing any registration to it when saving.
    if (!forceDisplayType) {
      patch.registration = registrationValidationError ? null : registrationType + value
    }
    setError(patch, "registration", registrationValidationError)
    setTouched(patch, "registration", true)
    onSetState(patch)
  }

  handleSetRegistrationNumberDebounced = debounce(this.handleSetRegistrationNumberSync, DEFAULT_DEBOUNCE)
  handleSetRegistrationNumber = event => {
    const { value } = event.target
    this.setState({ registrationNumber: value })
    this.handleSetRegistrationNumberDebounced(value)
  }

  validateRegistration = async (registrationType, value, registrations) => {
    let cleanValue = value
    if (registrationType && value && value.startsWith(registrationType)) {
      const registrationTypeLength = registrationType.length
      cleanValue = value.substring(registrationTypeLength)
    }
    const registration = registrationType + cleanValue
    const item = this.getPersonRegistrationItem(registrationType)
    return (
      checkRegistrationFormat(item?.formInputType || item?.value, cleanValue, item?.regExp && new RegExp(item.regExp)) ||
      (await this.scriptCheckPersonRegistrationFormat(registration, registrations))
    )
  }

  handleBlur = async () => {
    const { person, onSetState } = this.props
    const registrationType = this.getRegistrationType()

    const patch = {}
    if (this.isAutomaticRegistrationType(registrationType)) {
      setError(patch, "registration", null)
    } else if (person?.registration) {
      const error = await this.validateRegistration(registrationType, person.registration, person.registrations)
      setError(patch, "registration", error)

      // For the Page system, we need to make sure that this field is kept between updates,
      // as the person object cannot be controlled like it is here directly in the front-end.
      // Without this, invalid registrations are always removed, and navigating out of the input with tab
      // always remove valid and invalid registrations.
      patch.registration = person.registration
    } else {
      setError(patch, "registration", null)
    }

    onSetState(patch)
    this.setState({ showSearchResults: false })
  }

  scriptCheckPersonRegistrationFormat = async (registration, registrations) => {
    if (!getOptions("usePersonRegistrationFormatCheckScript")) return

    const { registrationTypeValues } = this.state
    let formatCheck
    await runScriptLocally({
      scriptName: "personRegistration-format-check", // this script is preloaded for offline use
      dynArgs: {
        req: {
          body: {
            registration,
            registrations,
            registrationTypeValues,
          },
        },
        res: {
          json: response => (formatCheck = response),
        },
      },
      acceptMissing: true,
    })
    return formatCheck?.error
  }

  getRegistrationType = () => {
    const { registrationTypeValues, personUserConfig } = this.state
    const { registration } = personUserConfig || {}
    const { person = {}, personType, displayFirstRegistrationsInPriority = registration?.displayFirstRegistrationsInPriority } = this.props
    const registrationWithoutTrigram = person?.registration?.substring(3)
    const trigram = registrationWithoutTrigram && person?.registration?.substring(0, 3)
    const existingDisplayedType = displayFirstRegistrationsInPriority
      ? person.registrations?.[0]?.type || trigram
      : trigram || person.registrations?.[0]?.type
    const forcedDisplayType = this.getForceDisplayType(person, personType)
    const foundForcedDisplayType = person.registrations?.find(it => it.type === forcedDisplayType)?.number
    if (forcedDisplayType && foundForcedDisplayType) return forcedDisplayType
    return existingDisplayedType || forcedDisplayType || registrationTypeValues?.[0]?.value
  }

  getRegistrationTypeIcon = registrationType => {
    const { registrationTypeValues = [] } = this.state
    return registrationTypeValues?.find(rt => rt.value === registrationType)?.icon
  }

  getProps = () => {
    const { person = {} } = this.props

    const configProps = getFieldProps(person, "registration")
    return { ...this.props, ...configProps }
  }

  render() {
    const {
      key,
      registrationTypeValues,
      showSearchModal,
      showRegistrationsModal,
      registrationNumber,
      loading,
      showConfirmationModal,
      personUserConfig: { registration = {} },
      searchResults,
      showSearchResults,
      selectedOptionIndex,
    } = this.state
    const {
      label: propsLabel,
      person = {},
      readOnly,
      showEdit = true,
      showSearch,
      showName,
      disabled,
      personType,
      placeholder,
      colProps,
      modelPath,
      filter,
      columns,
      advancedSearch,
      showRegistrationConfirmModal,
      hideClear,
      showCopy = true,
      displayFirstRegistrationsInPriority = registration.displayFirstRegistrationsInPriority,
      isProspect,
      showLabelType = true,
      modalRows,
      showMandatoryHint,
      personRegistrationsModal,
      linkTo = `/${isProspect ? "prospect" : "person"}/${person.registration}`,
    } = this.props

    const { mandatory, touched, errorFormat } = this.getProps()

    // The registrationNumber is empty when opening an existing person so we need the fallback to the person's registrations.
    const forceDisplayType = this.getForceDisplayType(person, personType)
    const forceDisplayRegistration = forceDisplayType && person?.registrations?.find(it => it.type === forceDisplayType)?.number
    const registrationType = this.getRegistrationType()
    const registrationWithoutTrigram =
      this.getPersonRegistrationItem(registrationType)?.mainRegistration === false ? undefined : person?.registration?.substring(3)
    const defaultDisplayedRegistration = displayFirstRegistrationsInPriority
      ? person?.registrations?.[0]?.number || registrationWithoutTrigram
      : registrationWithoutTrigram || person?.registrations?.[0]?.number
    const value = registrationNumber || forceDisplayRegistration || defaultDisplayedRegistration
    const isAutomaticRegistrationType = this.isAutomaticRegistrationType(registrationType)
    const isMainRegistration =
      person.registration && person.registration.substring(0, 3) === registrationType && person.registration.substring(3) === value

    let label = propsLabel
    if (!label) {
      if (isAutomaticRegistrationType) label = "Registration"
      else if (registrationType) label = this.getPersonRegistrationItemLabel(registrationType)
    } else {
      const registrationTypeLabel = this.getPersonRegistrationItemLabel(registrationType)
      if (!isAutomaticRegistrationType && registrationType && registrationTypeLabel) {
        label = loc(label)
        if (showLabelType) label += ` (${registrationTypeLabel})`
      }
    }

    label = label ? loc(label) : " "

    const registrationsModal = showRegistrationsModal && (
      <PersonRegistrationModal
        registrationTypeValues={registrationTypeValues}
        registrationType={registrationType}
        getLoadOptions={this.getLoadOptions}
        getPersonRegistrationItem={this.getPersonRegistrationItem}
        getPersonRegistrationItemLabel={this.getPersonRegistrationItemLabel}
        getPersonRegistrationRichValues={this.getPersonRegistrationRichValues}
        getSelectedIdData={this.getSelectedIdData}
        isAutomaticRegistrationType={this.isAutomaticRegistrationType}
        person={person}
        readOnly={readOnly}
        onClose={this.handleRegistrationsModalClose}
        rows={modalRows}
        validateRegistration={this.validateRegistration}
        personRegistrationsModal={personRegistrationsModal}
      />
    )

    const registrationTypeIcon = this.getRegistrationTypeIcon(registrationType)
    if (readOnly) {
      let displayRegistration = person.registration
      if (displayFirstRegistrationsInPriority && person?.registrations?.[0]?.number) displayRegistration = person.registrations[0].number
      if (forceDisplayType) {
        const entry = person.registrations?.find(it => it.type === forceDisplayType)
        if (entry) displayRegistration = (registrationTypeIcon ? "" : entry.type) + entry.number
      }

      const content = (
        <FormGroup className="person-registration-component">
          <ControlLabel>
            {label}
            {!registrationTypeIcon && person.registrations && person.registrations.length >= 2 && (
              <i className="c-pointer icn-list icn-xs max-h-14px text-info ml-5px" onClick={() => this.setState({ showRegistrationsModal: true })} />
            )}
          </ControlLabel>
          <FormControl.Static>
            {registrationTypeIcon && (
              <i className={`${registrationTypeIcon} mr-3 c-pointer`} onClick={() => this.setState({ showRegistrationsModal: true })} />
            )}
            {linkTo !== null ? <Link to={linkTo}>{displayRegistration}</Link> : <span>{displayRegistration}</span>}
          </FormControl.Static>

          {registrationsModal}
        </FormGroup>
      )

      if (colProps) return <Col {...colProps}>{content}</Col>
      return content
    }

    const registrationsModalAddon = showEdit ? (
      <InputGroup.Addon
        className={!disabled && "c-pointer"}
        onKeyDown={event => {
          if (disabled) return
          handleAccessibleOnKeyDown({ event, fn: () => this.setState({ showRegistrationsModal: true }) })
        }}
        onClick={() => !disabled && this.setState({ showRegistrationsModal: true })}
      >
        <ButtonWithTooltip bsStyle="default" btnClassName="m-0 pd-0" tooltip="Edit">
          <div>{registrationTypeIcon ? <i className={registrationTypeIcon} /> : registrationType}</div>
        </ButtonWithTooltip>
      </InputGroup.Addon>
    ) : null

    const loadOptions = this.getLoadOptions(registrationType)

    let control
    if (loadOptions) {
      control = (
        <div className="pseudo-select-control">
          {/* this div is necessary to keep a constant height between themes */}
          <div className="relative">
            <div className={`w-100${hideClear ? "" : " input-group"}`}>
              {registrationsModalAddon}
              <FormInput
                key={key}
                inputRef={this.personRegistrationRef}
                disabled={disabled}
                inArray
                showCopy
                debounce
                hideChevron
                onFocus={() => this.lookUpRegistrationNumber({ registration: value, withUpdate: false })}
                onBlur={this.handleBlur}
                // the name of the field must be registration so that in debug mode we know which field is the model it is
                field="registration"
                modelPath={modelPath}
                onSetState={this.lookUpRegistrationNumber}
                fcClassName="bbrr-theme btrr-theme"
                // Warning: when using showName the value of the input is different than the value really used in the data model
                // This is not an issue but you might be puzzled by that if you look at the DOM.
                value={value ? (showName && person.name ? `${person.name} (${value})` : value) : undefined}
                openMenuOnClick={false}
                placeholder={placeholder || "Select..."} // the label is often quite long so don't default it otherwise responsiveness will be broken
                noOptionsMessage={() => null}
                loadingMessage={() => loc("Loading")}
                onKeyDown={this.handleKeyDown}
              />

              {searchResults.length > 0 && value && showSearchResults && (
                <div className="pseudo-select-options">
                  {searchResults?.map((searchResult, index) => {
                    return (
                      // WARNING: we absolutely must use onMouseDown and not onClick
                      // because the browser might randomly prioritize the onBlur event of the accompanying form input,
                      // making this block disappear thus never trigerring the click event.
                      // And adding a setTimeout in the blur event handler has been tried and often fails so this is not a good solution.
                      <div
                        key={index}
                        className={`${pseudoSelectOptionClassName} leading-1-5 ${index === selectedOptionIndex ? "selected-option" : ""}`}
                        onMouseDown={event => this.handleSelectionConfirmation(searchResult, event)}
                        aria-label={searchResult.label}
                      >
                        {searchResult.label}
                      </div>
                    )
                  })}
                </div>
              )}

              <div className="pseudo-select-addons" onClick={() => this.handleAsyncSelectionChange()}>
                {showCopy && value && (
                  <ButtonWithTooltip
                    bsStyle="default"
                    disabled={loading}
                    className="icn-copy icn-xs flex-center"
                    btnClassName="m-0 min-w-0 text-gray-darker flex-center min-w-24px min-h-24px"
                    tooltip="Copy"
                    onClick={event => {
                      event.stopPropagation()
                      copyToClipboard(value)
                    }}
                  />
                )}

                {!hideClear && value && (
                  <ButtonWithTooltip
                    bsStyle="default"
                    disabled={loading}
                    className="icn-xmark icn-xs flex-center"
                    btnClassName="m-0 min-w-0 text-gray-darker flex-center min-w-24px min-h-24px"
                    tooltip="Clear"
                    onClick={event => {
                      event.stopPropagation()
                      this.handleAsyncSelectionChange()
                    }}
                  />
                )}

                <ButtonWithTooltip
                  bsStyle="default"
                  disabled={loading}
                  className={"icn-xs flex-center " + (loading ? "icn-circle-notch icn-spin" : "icn-chevron-down")}
                  btnClassName="m-0 min-w-0 text-gray-darker flex-center min-w-24px min-h-24px"
                  tooltip="Select"
                  onClick={event => {
                    event.stopPropagation()
                    this.personRegistrationRef.current?.focus()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      control = (
        <>
          {registrationsModalAddon}
          {disabled || readOnly || isAutomaticRegistrationType || (person._id && isMainRegistration) ? (
            <div disabled className="form-control">
              {value || ""}
            </div>
          ) : (
            <FormControl
              id={modelPath || label}
              type="text"
              value={value || ""}
              readOnly={readOnly}
              bsClass="form-control"
              onChange={this.handleSetRegistrationNumber}
              disabled={disabled || readOnly || isAutomaticRegistrationType || (person._id && isMainRegistration)}
            />
          )}
        </>
      )
    }

    let errorMessage = errorFormat
    if (!errorMessage && mandatory && touched && isEmpty(value) && !readOnly && !isAutomaticRegistrationType) {
      errorMessage = "Person identifier is mandatory"
    }

    const content = (
      <>
        <FormGroup className="person-registration-component" data-model-field-path={`${modelPath ? `${modelPath}.` : ""}registration`}>
          <ControlLabel htmlFor={modelPath || label}>{label}</ControlLabel>
          {showMandatoryHint && (
            <span className="form-input-mandatory-hint text-danger">
              <i className="icn-asterisk icn-xxxs relative bottom-5px" />
            </span>
          )}
          <InputGroup>
            {control}
            {showSearch && (
              <InputGroup.Addon className="c-pointer" onClick={() => this.setState({ showSearchModal: true })}>
                <i className="icn-search icn-xs" />
              </InputGroup.Addon>
            )}
          </InputGroup>
          {errorMessage && (
            <span className="validation-error">
              {typeof errorMessage === "string" ? loc(errorMessage) : Array.isArray(errorMessage) ? loc(...errorMessage) : null}
            </span>
          )}
        </FormGroup>

        {registrationsModal}

        {showSearchModal && (
          <EntitySearchModal
            columns={columns}
            entityName="Person"
            advancedSearch={advancedSearch}
            onClose={this.handleSearchModalClose}
            query={{ type: personType || "C", ...filter }}
          />
        )}

        {showRegistrationConfirmModal && showConfirmationModal && (
          <Modal show={true} onHide={() => this.handleCloseConfirmation()} backdrop="static" className="person-registration-confirmation-modal">
            <Modal.Header closeButton>
              <Modal.Title>{loc(showRegistrationConfirmModal?.title || "Confirm your selection")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <pre>{showConfirmationModal?.label}</pre>
            </Modal.Body>

            <Modal.Footer>
              <CustomButton bsSize="sm" onClick={() => this.handleCloseConfirmation()}>
                {loc(showRegistrationConfirmModal?.cancelButtonTitle || "No")}
              </CustomButton>

              <CustomButton bsSize="sm" bsStyle="primary" fill onClick={() => this.handleCloseConfirmation(true)}>
                {showConfirmationModal?.loading && <i className="icn-circle-notch icn-sm icn-spin text-gray" />}
                {!showConfirmationModal?.loading && loc(showRegistrationConfirmModal?.confirmButtonTitle || "Yes")}
              </CustomButton>
            </Modal.Footer>
          </Modal>
        )}
      </>
    )

    if (colProps) return <Col {...colProps}>{content}</Col>
    return content
  }
}

export default PersonRegistrationComponent
