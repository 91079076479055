import React from "react"
import { Row } from "react-bootstrap"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import EntityBankAccountsModal from "@/_components/EntityBankAccountsModal"
import FormInputExtended from "@/_components/FormInputExtended"
import PanelInner from "@/_components/PanelInner"

import { getOptions } from "@/_services/userConfiguration"

class EntityBankAccountsPanel extends React.Component {
  constructor(props) {
    super(props)

    const { previewFields } = getOptions("bankAccount", {})

    this.state = {
      previewFields,
      showModal: false,
    }
  }

  handleSetEntityBankAccountState = ({ patch, index }) => {
    if (!patch) return

    const { entity, handleSetEntityState, entityName } = this.props
    const isPerson = entityName === "Person"

    if (patch.bankIban) patch.bankIban = patch.bankIban.replace(/ /g, "") // Remove spaces (if pasted !)

    const bankAccounts = entity?.bankAccounts || []
    bankAccounts[index] = { ...(bankAccounts[index] || {}), ...patch }

    if (bankAccounts[index].bankIban && !bankAccounts[index].name) {
      if (isPerson && entity?.type === "C" && entity?.name) {
        bankAccounts[index].name = entity.name
      } else if (isPerson && entity?.type === "I" && (entity?.firstName || entity?.lastName)) {
        bankAccounts[index].name = `${entity.firstName || ""} ${entity.lastName || ""}`.trim()
      }
    }

    handleSetEntityState({ bankAccounts })
  }

  render() {
    const { showModal: _showModal, previewFields } = this.state
    let {
      hidden,
      entity,
      title = "Banking information",
      collapse = false,
      readOnly,
      modelPath,
      defaultFields,
      showModal = true,
      showSepaMandates,
      modalDefaultFields,
      showCollectionMethods,
      handleSetEntityState,
    } = this.props

    const hideBankAccounts = entity?.props_bankAccounts?.hidden === true
    if (hidden || hideBankAccounts) return null

    if (!readOnly && entity?.props_bankAccounts?.readOnly) readOnly = true

    const bankAccounts = entity?.bankAccounts || []
    const isPreview = previewFields === true || previewFields?.length > 0 || readOnly

    return (
      <>
        <PanelInner
          title={title}
          collapse={collapse}
          className="entity-bank-accounts-panel"
          buttons={
            showModal && (
              <ButtonWithTooltip
                fill
                bsSize="xs"
                bsStyle="primary"
                className={`icn-${readOnly ? "eye" : "edit"} icn-xs`}
                btnClassName="pull-right flex-center"
                data-test="edit-person-banking-info-btn"
                onClick={() => this.setState({ showModal: true })}
              />
            )
          }
        >
          <Row>
            {isPreview &&
              bankAccounts.map((bankAccount, index) => (
                <FormInputExtended
                  key={index}
                  obj={bankAccount}
                  type="bankAccount"
                  readOnly={readOnly}
                  isPreview={isPreview}
                  modelPath={(modelPath ? `${modelPath}.` : "") + `bankAccounts[${index}]`}
                />
              ))}
            {!isPreview && (
              <FormInputExtended
                obj={bankAccounts[0]}
                type="bankAccount"
                readOnly={readOnly}
                onSetState={patch => this.handleSetEntityBankAccountState({ patch, index: 0 })}
                modelPath={(modelPath ? `${modelPath}.` : "") + "bankAccounts[0]"}
                fields={
                  defaultFields || [
                    { field: "bankIban", debounce: true, colProps: { xs: 12, sm: 5 } },
                    { field: "bankBic", colProps: { xs: 12, sm: 3 } },
                    { field: "bankName", colProps: { xs: 12, sm: 4 } },
                    { field: "bankOwner", colProps: { xs: 12, sm: 5 } },
                    { field: "bankOpeningDate", colProps: { xs: 12, sm: 3 } },
                    { field: "bankPreferredWithdrawalDate", colProps: { xs: 12, sm: 4 } },
                    { field: "sepaMandates", hidden: !showSepaMandates },
                  ]
                }
              />
            )}
          </Row>
        </PanelInner>
        {_showModal && (
          <EntityBankAccountsModal
            entity={entity}
            readOnly={readOnly}
            modelPath={modelPath}
            defaultFields={modalDefaultFields}
            showSepaMandates={showSepaMandates}
            handleSetEntityState={handleSetEntityState}
            showCollectionMethods={showCollectionMethods}
            onClose={() => this.setState({ showModal: false })}
            handleSetEntityBankAccountState={this.handleSetEntityBankAccountState}
          />
        )}
      </>
    )
  }
}

export default EntityBankAccountsPanel
