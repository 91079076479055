import React from "react"
import { Row } from "react-bootstrap"

import PanelInner from "@/_components/PanelInner"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import FormInputExtended from "@/_components/FormInputExtended"
import PersonEditAddressesModal from "@/person/PersonEditAddressesModal"

class AddressPanel extends React.Component {
  state = {
    showEditAddressesModal: false,
  }

  handleEditPersonAddressesModalClose = () => {
    this.setState({ showEditAddressesModal: false })
  }

  handleSetAddressState = patch => {
    const { entity, handleSetEntityState } = this.props
    let entityPatch
    if (patch) {
      const copyAddresses = (entity?.addresses || []).map(address => ({ ...address }))
      if (!copyAddresses.length) copyAddresses.push({})
      copyAddresses[0] = { ...copyAddresses[0], ...patch }
      entityPatch = { addresses: copyAddresses }
    }
    handleSetEntityState(entityPatch)
  }

  render() {
    const { showEditAddressesModal } = this.state
    let {
      entity,
      readOnly,
      bsSize,
      bsStyle,
      modelPath,
      handleSetEntityState,
      show,
      extraInfo,
      language,
      region,
      showOpenModalButton = true,
      title = "Address",
    } = this.props
    const { addresses } = entity
    const [address = {}] = addresses || []

    if (!readOnly && entity?.props_addresses?.readOnly) readOnly = true

    return (
      <PanelInner
        title={title}
        collapse={false}
        className={"address-panel " + (show ? "" : "d-none")}
        buttons={
          showOpenModalButton && (
            <ButtonWithTooltip
              bsStyle={bsStyle || "primary"}
              bsSize={bsSize || "xs"}
              fill
              className={`icn-${readOnly ? "eye" : "edit"} icn-xs`}
              btnClassName="pull-right flex-center"
              data-test="edit-person-address-btn"
              onClick={() => this.setState({ showEditAddressesModal: true })}
              tooltip="Edit"
            />
          )
        }
      >
        <Row>
          <FormInputExtended
            singleRow
            showDetails
            obj={address}
            type="address"
            readOnly={readOnly}
            extraInfo={extraInfo}
            language={language}
            region={region}
            colProps={{ xs: 12, sm: 6 }}
            onSetState={this.handleSetAddressState}
            modelPath={(modelPath ? `${modelPath}.` : "") + "addresses[0]"}
          />
        </Row>
        {showEditAddressesModal && (
          <PersonEditAddressesModal
            readOnly={readOnly}
            extraInfo={extraInfo}
            language={language}
            region={region}
            person={entity}
            modelPath={modelPath}
            onClose={this.handleEditPersonAddressesModalClose}
            handleSetEntityState={handleSetEntityState}
          />
        )}
      </PanelInner>
    )
  }
}

export default AddressPanel
