import AppLoadingAnimation from "@/_components/AppLoadingAnimation"
import JsChunksErrorBoundary from "@/_components/JsChunksErrorBoundary"
import { isAuthenticated } from "@/_services/authentication"
import { getHeaderAuthorization, resetHeaderAuthorization } from "@/_services/axios"
import { registerNotifications } from "@/_services/notification"
import { isOffline, registerOfflineStateUpdateFn, setOffline } from "@/_services/offlineService.js"
import { authenticate as authenticateWithOidc, cleanup as cleanupOidc, refreshAccessToken } from "@/_services/oidc"
import { authenticate as authenticateWithSaml, cleanup as cleanupSaml } from "@/_services/saml"
import { getNoDecoration, setHostConfig, setNoDecoration, setPageTitle, toggleNavControlsDisplayMode } from "@/_services/theming"
import * as uiStateService from "@/_services/uiState"
import { canReadScripts, getIsAdmin, getOptions, getUserPermissions, setInitialUserContext } from "@/_services/userConfiguration"
import {
  appModes,
  checkIfInIframe,
  checkTenantConfig,
  customEvents,
  entityModelBtnId,
  globalSearchBtnId,
  isNewTabOrWindowClick,
  isSameOrigin,
  putNavigationHistory,
  searchParamToObject,
  selfIframeMessages,
  selfIframeSources,
  setMarkedRenderedOptions,
  sideColumnToggleId,
  toggleDebugMode,
  virtualKeyboardToggleBtnId,
} from "@/_services/utils"
import { pagesLayoutRoutes, rootRoutes } from "@/routes.js"
import axios from "axios"
import React, { Component, Suspense } from "react"
import NotificationSystem from "react-notification-system"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"

const PagesLayout = React.lazy(() => import("@/_pages/PagesLayout") /* webpackChunkName: "precache" */)
const Page = React.lazy(() => import("@/_pages/Page"))

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      appMode: null,
      isLoadingApp: true,
      offlineSince: null,
    }
  }

  /**
   * Warning: code order matters here because the execution of the function can be cut short !
   * Do not swap or add code in random places or you can break the app.
   * We could have coded things with several "if" but it would be less readable.
   */
  async componentDidMount() {
    const { history } = this.props
    const { location } = history || {}
    const { pathname = "", search } = location || {}
    const { userContext: initialUserContextNameBase64X2, newAuth, clearCache } = searchParamToObject(search)

    // The option name is about cache because we might want one day to clear everything and not just local storage.
    if (clearCache) {
      localStorage.clear()
    }

    // This query param is used when we want to force users to login again notably those using external auth protocols.
    // Indeed when users have logged in with an auth protocol, our platform generated a token that is independent from the protocol
    // and that has its own lifetime, which we use to avoid users' browsers always going through the login page
    // (even if quickly, in which case a flash might appear) when opening a new tab.
    // To do that we check first that the token stored in localStorage is valid before deciding to direct them to the external login page.
    if (newAuth === "true") {
      resetHeaderAuthorization()
      cleanupOidc()
      cleanupSaml()
    }

    try {
      // 1. Are we resolving a short URL ?
      const shortUrlMarker = "/u/"
      const indexOfShortUrlMarker = pathname.indexOf(shortUrlMarker)
      if (indexOfShortUrlMarker === 0) {
        try {
          const {
            data: { url },
          } = await axios.get(`/api/core/key-values/${pathname.substring(shortUrlMarker.length)}?shortUrl=1`)
          if (url) return (window.location.href = url)
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      // The ui state must be restored before starting to load options from any source, here from the host config.
      // Otherwise the first call to setUiState might start to use an empty object due to the root of the ui state not yet being loaded.
      uiStateService.initUiState()
      const hostConfig = await setHostConfig()

      // 2. Is the front end allowed to be loaded on this host ?
      if (hostConfig.loadFrontEnd === false) return

      // 3. Is the app in Flow mode ?
      if (hostConfig.mode === appModes.FLOW) {
        this.setState({ appMode: hostConfig.mode, isLoadingApp: false })
        return
      }

      // 4.1. Are we using OIDC ?
      // The OIDC might redirect the user outside the app to perform the login.
      const { isRedirectingToOidcProvider } = (await authenticateWithOidc({ history })) || {}
      if (isRedirectingToOidcProvider) return

      // 4.2. Are we using SAML ?
      // The SAML might redirect the user outside the app to perform the login.
      const { isRedirectingToSamlProvider } = (await authenticateWithSaml({ history })) || {}
      if (isRedirectingToSamlProvider) return

      // 5. Are we using offline mode ?
      registerOfflineStateUpdateFn(offlineSince => this.setState({ offlineSince }), history)
      if (!navigator.onLine) setOffline(true)

      // 6. Standard mode, code order here matters less
      document.addEventListener("keydown", e => this.handleKeyboardShortcuts(e), false)

      if (checkIfInIframe()) {
        document.addEventListener("contextmenu", this.handleIframeContextMenu, false)
        document.addEventListener("mousedown", this.handleIframeLink, false)

        window.addEventListener("message", this.handleSameOriginParentWindowMessage)
        window.parent.postMessage(selfIframeMessages.READY, window.location.origin)
      }

      setMarkedRenderedOptions()

      if (initialUserContextNameBase64X2) {
        setInitialUserContext({ initialUserContextNameBase64X2 })
      }

      this.setState({ isLoadingApp: false })
    } catch (err) {
      // if something fails, don't render anything for better security
    }
  }

  componentDidUpdate() {
    setPageTitle()
    const { noDecoration, parentUrl } = searchParamToObject(window.location.search)
    if (["1", "true"].includes(noDecoration)) setNoDecoration(true)
    if (parentUrl) this.parentUrl = parentUrl
    if (this.state.isLoadingApp === false) {
      registerNotifications(this.refs.notificationSystem)
    }

    refreshAccessToken()
    putNavigationHistory()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", e => this.handleKeyboardShortcuts(e), false)

    if (checkIfInIframe()) {
      document.removeEventListener("contextmenu", this.handleIframeContextMenu, false)
      document.removeEventListener("mousedown", this.handleIframeLink, false)

      window.removeEventListener("message", this.handleSameOriginParentWindowMessage)
    }
  }

  handleSameOriginParentWindowMessage = event => {
    const { history } = this.props
    const { origin, data } = event

    // Security check: the app can only embed itself when not in Flow mode.
    // Note that the Page system (see Page.jsx) also does this check but it can be configured,
    // whereas here we don't allow configuration.
    if (origin !== window.location.origin) return

    try {
      const parentWindowData = JSON.parse(data)
      const { url, source } = parentWindowData

      // Another check, but this time for app maintenance so we know where messages come from
      // and also to write code only needed for that source.
      if (source === selfIframeSources.SIDE_VIEW) {
        if (url) {
          history.replace(url)
          document.getElementsByTagName("body")[0].scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
        return
      }
    } catch (error) {
      console.log("The parent window message is not a JSON")
    }
  }

  handleKeyboardShortcuts = event => {
    if (!isAuthenticated()) return

    refreshAccessToken()

    const { metaKey, ctrlKey, shiftKey, altKey: ALT, keyCode } = event
    const { history } = this.props
    const isMac = window.navigator.platform.match("Mac")
    const CTRL = isMac ? metaKey : ctrlKey
    const SHIFT = shiftKey
    const U = keyCode === 85
    const B = keyCode === 66
    const C = keyCode === 67
    const D = keyCode === 68
    const G = keyCode === 71
    const M = keyCode === 77
    const R = keyCode === 82
    const S = keyCode === 83
    const T = keyCode === 84
    const X = keyCode === 88
    const F9 = keyCode === 120
    const ESC = keyCode === 27
    const canOpenQueryToken = canReadScripts() || getOptions("allowOpenQueryToken")
    const isAdmin = getIsAdmin()
    const allowDebugMode = getOptions("allowDebugMode") ?? true
    // Users connected without any permissions (i.e. without any profile) must be restricted
    // this can happen especially when connecting through OIDC with bad checks on app access.
    const userPermissions = getUserPermissions()
    const hasPermissions = Object.keys(userPermissions || {}).length > 0

    if (ALT && R) {
      event.preventDefault()
      window.dispatchEvent(new CustomEvent(customEvents.pagesLayout.reloadRoute))
    }

    if (ALT && T) {
      event.preventDefault()
      toggleNavControlsDisplayMode()
    }

    if (((CTRL && U) || (CTRL && SHIFT && U)) && (hasPermissions || isAdmin)) {
      event.preventDefault()
      checkTenantConfig()
    }

    // this is for debug purpose
    if (CTRL && SHIFT && C) {
      event.preventDefault()
      setOffline(!isOffline(), true)
    }

    if (CTRL && D && (hasPermissions || isAdmin) && allowDebugMode) {
      // disable this combination on the login page because this is the "add bookmark" shortcut
      // for major browsers (Chrome, Firefox) and thus would require the user to focus its mouse
      // elsewhere to trigger it
      if (window.location.pathname === "/authentication/login-page") return
      event.preventDefault()
      toggleDebugMode(history)
    }

    if (CTRL && G) {
      event.preventDefault()
      document.getElementById(globalSearchBtnId)?.click()
    }

    if (CTRL && SHIFT && S && canReadScripts()) {
      history.push("/scripts")
    }

    // Submit form on CTRL + S
    // Warning : this applies to any first button of type submit found in the current page
    // so if you have multiple submit button, place first in the DOM the one you want to be
    // triggered when using this keyboard combination.
    if (CTRL && S) {
      event.preventDefault()
      const submitBtn = document.querySelector('button[type="submit"]')
      if (submitBtn) submitBtn.click()
    }

    // collapse all cards
    if (ALT && C) window.dispatchEvent(new CustomEvent(customEvents.card.toggle))

    if (CTRL && SHIFT && X && canOpenQueryToken) {
      const token = getHeaderAuthorization()?.substring("bearer ".length)
      if (!token) return
      const url = window.location.origin + "/api/script/runs/hello-world?who=basikon&token=" + token
      const win = window.open(url, "_blank")
      win.focus()
    }

    // Same as settings toggling (scriptsPage)
    if (CTRL && B) document.getElementById(sideColumnToggleId)?.click()

    // Shows the virtual keyboard
    if (ALT && B) document.getElementById(virtualKeyboardToggleBtnId)?.click()

    // Shows the entity modal
    if (CTRL && M) document.getElementById(entityModelBtnId)?.click()

    if (F9 && !checkIfInIframe()) {
      setNoDecoration(!getNoDecoration())
      window.dispatchEvent(new Event("resize"))
    }

    if ((ALT && X) || ESC) {
      if (checkIfInIframe()) {
        window.parent.postMessage(customEvents.sideView.toggleView, window.location.origin)
      } else {
        window.dispatchEvent(new CustomEvent(customEvents.sideView.toggleView))
      }
    }
  }

  findLink = event => {
    let link
    const linkIndex = event.composedPath().findIndex(it => it.tagName?.toLowerCase() === "a")
    if (event.target.tagName.toLowerCase() === "a") link = event.target
    else if (linkIndex > 0) {
      let tmp = event.target.parentNode
      for (let i = 1; i <= linkIndex; i++) {
        if (tmp.tagName.toLowerCase() === "a") link = tmp
        else tmp = tmp.parentNode
      }
    }
    return link
  }

  /**
   * When the app is embedded in a iframe
   * if the user choose to open a link in a new tab
   * by opening the context menu with a right-click then choosing "open in a new tab" option
   * we force the link to point to the parent url (of the iframe) so that the user cannot "exit" the iframe
   */
  handleIframeContextMenu = event => {
    const link = this.findLink(event)
    if (link && this.parentUrl && !link.href.includes(this.parentUrl)) {
      link.href = `${this.parentUrl}?basikOnPath=${link.href.replace(window.location.origin, "")}`
    }
  }

  /**
   * Same as handleIframeContextMenu but for other means of opening a link not in the current view :
   * - new tab with click + ctrl (or cmd for mac)
   * - new window with click + shift
   * - new tab middle click (scroll wheel)
   */
  handleIframeLink = event => {
    const link = this.findLink(event)
    if (!link) return

    if (isNewTabOrWindowClick(event)) {
      if (this.parentUrl && !link.href.includes(this.parentUrl)) {
        link.href = `${this.parentUrl}?basikOnPath=${link.href.replace(window.location.origin, "")}`
      }
    } else if (link.href?.startsWith("http") && isSameOrigin()) {
      // This handles opening absolute links from within the SideView.
      // Instead of opening them in place which breaks the iframe, we force them to open a new tab.
      link.target = "_blank"
      link.rel = "noopener noreferrer"
    }
  }

  render() {
    const { appMode, isLoadingApp, offlineSince } = this.state

    if (isLoadingApp) return <AppLoadingAnimation />

    const routeContent =
      appMode === appModes.FLOW ? (
        <Route
          path="/k/:accessKey/p/:pageId"
          render={routeProps => (
            <Suspense fallback={null}>
              <Page {...routeProps} appMode={appMode} />
            </Suspense>
          )}
        />
      ) : (
        <Route
          path="/"
          render={routeProps => {
            const { location } = routeProps

            // the documentation pages use the hash as the standard native anchor to point to content headers
            if (location.hash && !location.pathname.startsWith("/documentation")) {
              return <Redirect to={location.hash.substring(1)} />
            }

            for (let i = 0; i < rootRoutes.length; i++) {
              const rootRoute = rootRoutes[i]
              if (location.pathname.startsWith(rootRoute.path)) return rootRoute.render()
            }

            return (
              <Suspense fallback={null}>
                <PagesLayout {...routeProps} routes={pagesLayoutRoutes} offlineSince={offlineSince} />
              </Suspense>
            )
          }}
        />
      )

    return (
      <JsChunksErrorBoundary>
        <NotificationSystem ref="notificationSystem" />
        <Suspense fallback={null}>
          <Switch>{routeContent}</Switch>
        </Suspense>
      </JsChunksErrorBoundary>
    )
  }
}

export default withRouter(App)
