import React, { Component } from "react"

import { loc } from "@/_services/localization"

class CustomRadio extends Component {
  render() {
    const {
      value,
      number,
      label,
      option,
      options,
      name = "",
      onChange = () => {}, // this shutdowns the browser error when the change is handled by click
      disabled,
      onClick,
      checked,
      inline,
      displayMode,
      autoComplete,
      errorDisplay,
    } = this.props

    if (Array.isArray(options)) {
      return (
        <div className="radio-group" data-inline={inline || displayMode === "inline"}>
          {options.map((selectOption, index) => {
            return (
              <div className="radio" key={index}>
                <input
                  id={number + name + selectOption.value}
                  name={name}
                  type="radio"
                  value={selectOption.value}
                  checked={selectOption.value === value}
                  onClick={onClick}
                  onChange={onChange}
                  disabled={disabled}
                  autoComplete={autoComplete}
                  errorDisplay={errorDisplay}
                />
                <label htmlFor={number + name + selectOption.value}>{selectOption.label}</label>
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <div className="radio">
        <input
          id={number}
          name={name}
          type="radio"
          value={option}
          checked={checked ?? value === option}
          disabled={disabled}
          onClick={onClick}
          onChange={onChange}
          autoComplete={autoComplete}
          errorDisplay={errorDisplay}
        />
        <label htmlFor={number}>{loc(label)}</label>
      </div>
    )
  }
}

export default CustomRadio
