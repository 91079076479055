import axios from "axios"
import cloneDeep from "lodash.clonedeep"
import debounce from "lodash.debounce"
import React, { Fragment } from "react"
import { Col, Dropdown, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import FormInputExtended from "@/_components/FormInputExtended"
import { setWithMutation } from "@/_services/inputUtils"
import { getLocale, loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { getConfigAtPath } from "@/_services/userConfiguration"
import {
  customEvents,
  DEFAULT_DEBOUNCE,
  downloadFileFromUrl,
  mergeQueryParams,
  onDropdownMenuButtonKeyDown,
  onDropdownToggleKeyDown,
  searchParamToObject,
} from "@/_services/utils"
import { checkErrors, isEqual, objectToSearchParam, PROPS_, replaceUrlParams, replaceVariables, utcParseDate, YYYYMMDD } from "basikon-common-utils"

/**
 * @prop {boolean}      urlQueryFields      Store field values in query                     Default to false.
 * @prop {object}       defaultValues       Fields default values                           Default to {}.
 * @prop {[[object]]}   fields              List of fields, [[]] => rows of columns         Default to [].
 * @prop {[object]}     buttons             List of buttons to be shown after the fields    Default to [].
 */

class FormContent extends React.Component {
  state = {
    form: {},
    loading: false,
    isUsingPage: true,
  }

  componentDidMount() {
    const { location, defaultValues = {}, urlQueryFields } = this.props

    const { pathname } = location
    let queryParams = searchParamToObject(location.search)

    if (urlQueryFields) queryParams = this.unformat(queryParams)
    const statePatch = { form: { ...queryParams, ...defaultValues } }
    // page in normal and flow mode (/k)
    if (pathname.startsWith("/page") || pathname.startsWith("/k")) statePatch.isUsingPage = true
    this.setState(statePatch)
  }

  componentDidUpdate(prevProps, prevState) {
    const { form: prevForm } = prevState
    const { form } = this.state
    const { obj, urlQueryFields, location, defaultValues = {} } = this.props
    const { location: prevLocation, defaultValues: prevDefaultValues = {} } = prevProps

    let formPatch = {}
    if (!isEqual(defaultValues, prevDefaultValues)) {
      formPatch = { ...form, ...defaultValues }
    }

    if (obj && !isEqual(prevForm, { ...form, ...obj })) formPatch = { ...formPatch, ...form, ...obj }

    if (urlQueryFields) {
      // Re-render if any query param changes
      const queryParams = searchParamToObject(location.search)
      const prevQueryParams = searchParamToObject(prevLocation.search)

      if (!isEqual(queryParams, prevQueryParams)) formPatch = { ...formPatch, ...form, ...queryParams }
    }
    if (Object.keys(formPatch).length) this.setState({ form: formPatch })
  }

  format = obj => {
    const { fields = [] } = this.props

    const queryParams = {}
    for (const key in obj) {
      if (key?.startsWith(PROPS_)) continue

      const field = fields.flat().find(field => field?.formInputProps?.field === key)
      const { type, multiple } = field?.formInputProps || {}

      // Date => String
      if (type === "date" && obj[key] instanceof Date) queryParams[key] = YYYYMMDD(obj[key])
      // ["String1", "String2"] => "String1,String2"
      else if (multiple && Array.isArray(obj[key])) queryParams[key] = obj[key].join(",")
      else queryParams[key] = obj[key]
    }
    return queryParams
  }

  unformat = obj => {
    const { fields = [] } = this.props

    for (let key in obj) {
      const field = fields.flat().find(field => field?.formInputProps?.field === key)
      const { type, multiple } = field?.formInputProps || {}

      // String => Date
      if (type === "date" && typeof obj[key] === "string") {
        const locale = getLocale()
        obj[key] = utcParseDate(obj[key], locale)
      }
      // "String1,String2" => ["String1", "String2"]
      if (multiple && obj[key]?.includes(",")) obj[key] = obj[key].split(",")
    }
    return obj
  }

  handleSetFormStateSync = (patch, formInputProps) => {
    // execComputations might be set by the layout script to trigger computation whenever a field is changed
    const { handlers, urlQueryFields, onSetState, execComputations } = this.props
    let { field, obj, multiple } = formInputProps

    if (Object.keys(formInputProps).length === 0) return

    // TODO : merge this block with the other one in getFormElement
    // for now for an unknown reason putting the code in a function does not give the same results...
    let extendedOnSetState = onSetState
    if (typeof obj === "string" && handlers) {
      const handler = handlers[obj]
      if (!handler) addOops(loc("Invalid object $ in 'layout' script", obj))
      else {
        if (handler.obj) obj = handler.obj
        if (handler.onSetState) extendedOnSetState = patch => handler.onSetState(patch, execComputations)
      }
    }

    let patchedForm
    if (patch) {
      if (typeof obj === "string") patch = { [obj]: patch }
      if (urlQueryFields === true || (Array.isArray(urlQueryFields) && urlQueryFields.includes(Object.keys(patch)[0]))) {
        const {
          history,
          location: { pathname, search },
        } = this.props

        const queryParams = mergeQueryParams(search, this.format(patch))
        history.replace(`${pathname}${queryParams}`)
      } else {
        const { form = {} } = this.state
        if (field && multiple && Array.isArray(patch)) patch = { [field]: patch.map(patch => patch.value) }

        // Remove isChanged flag from form (stored by entity in componentDidUpdate())
        // otherwise the save button will remain disabled because isChanged is false
        delete form.isChanged

        // We can't use JSON.parse(JSON.stringify()) here because we want to keep the format of the variables inside the form
        // typically dates etc.

        // this code will mutate siblings => will generate more (slow) refresh than needed
        // const patchedForm = cloneDeep(form)
        // for (const patchKey in patch) {
        // set(patchedForm, patchKey, patch[patchKey])
        // }
        patchedForm = { ...form }
        for (const patchKey in patch) {
          setWithMutation(patchedForm, patchKey, patch[patchKey])
        }
      }
    }

    const statePatch = {}
    if (patchedForm) statePatch.form = patchedForm
    this.setState(statePatch)
    if (extendedOnSetState) extendedOnSetState(patchedForm)
  }

  handleSetFormStateDebounced = debounce(this.handleSetFormStateSync, this.state.isUsingPage ? 0 : DEFAULT_DEBOUNCE)
  handleSetFormState = (patch, formInputProps) => {
    this.handleSetFormStateDebounced(patch, formInputProps)
  }

  handleSubmit = async event => {
    event.preventDefault()

    const { form = {} } = this.state
    let {
      history,
      uri, // TODO: Remove me after release 22/11/2022 (renamed to "url")
      url,
      redirectTo,
      method,
      successMessage,
      reloadAfterSuccess,
    } = this.props
    if (!url && uri) url = uri // TODO: Remove me after release 22/11/2022

    if (!url) return

    if (checkErrors(form, form => this.setState({ form }))) return

    this.setState({ loading: true })

    try {
      let result
      if (method === "GET") result = (await axios.get(url + objectToSearchParam(form))).data || {}
      else result = (await axios.post(url, form)).data || {}

      if (!redirectTo && result.redirectTo) redirectTo = result.redirectTo
      if (successMessage) addNotification(successMessage)

      if (redirectTo) {
        redirectTo = replaceVariables(redirectTo, form)

        if (redirectTo.startsWith("http")) window.location.href = redirectTo
        else history.push(redirectTo)
      } else if (reloadAfterSuccess) {
        setTimeout(() => window.location.reload(), 500)
      } else {
        const landingPage = getConfigAtPath("landingPage")
        if (landingPage) history.push(landingPage)
      }
    } catch (error) {
      addOops(error)
    }
    this.setState({ loading: false })
  }

  handleButtonClick = async ({ url, redirectTo, linkTo, download, pageState, softReload } = {}) => {
    let { form = {} } = this.state
    const { history, onSetState } = this.props

    // If no useful arguments are passed as it is a configuration choice just do nothing silently.
    // Users should not see configuration-related messages.

    // This line is to provide the latest version of the form to subsequent code so that for instance
    // a button with an onClick handler can update the form and trigger a URL redirection in the same function.
    if (pageState) form = { ...form, ...pageState }
    if (onSetState && pageState) onSetState(form)

    if (linkTo) {
      if (typeof linkTo === "string") {
        if (linkTo.startsWith("http")) {
          window.location.href = replaceUrlParams(linkTo, form)
        } else {
          history.push(replaceUrlParams(linkTo, form))
        }
      } else if (typeof linkTo === "object" && linkTo.url) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/open
        window.open(replaceUrlParams(linkTo.url, form), linkTo.target, linkTo.windowFeatures)
      }
      return
    }

    if (url && download) return await downloadFileFromUrl(url, "Downloaded", "Downloading")

    if (softReload) {
      window.dispatchEvent(new CustomEvent(customEvents.pagesLayout.reloadRoute))
    }

    if (url) {
      try {
        this.setState({ loading: true })
        const result = (await axios.post(url, form)).data
        if (!redirectTo && result.redirectTo) redirectTo = result.redirectTo

        this.redirect(redirectTo, form)
      } catch (error) {
        addOops(error)
      } finally {
        this.setState({ loading: false })
      }
    } else if (redirectTo) {
      this.redirect(redirectTo, form)
    }
  }

  redirect = (url, obj) => {
    const { history } = this.props
    if (!url) return

    if (typeof url === "string") {
      url = replaceVariables(url, obj)
      if (url.startsWith("http")) window.location.href = url
      else history.push(url)
    } else if (typeof url === "object" && url.url) {
      url.url = replaceVariables(url.url, obj)
      window.open(url.url, url.target, url.windowFeatures)
    }
  }

  generateButton(buttonProps, index = 0) {
    if (Array.isArray(buttonProps)) {
      return buttonProps.map((_buttonProps, jndex) => this.generateButton(_buttonProps, `${index}-${jndex}`))
    }

    const { form, loading } = this.state
    let {
      uri, // TODO: Remove me after release 22/11/2022 (renamed to "url")
      url,
      label,
      linkTo,
      redirectTo,
      pullRight = true,
      fill = true,
      simple = false,
      bsStyle = "primary",
      bsSize = "small",
      className = "",
      pageState,
      colProps,
      icon,
      onClick,
      download,
      showLabel = true,
      splitButtonMenus,
      tooltip,
      iconClassName,
      ...props
    } = buttonProps
    if (!url && uri) url = uri // TODO: Remove me after release 22/11/2022 (renamed to "url")

    let content
    if (splitButtonMenus) {
      if (props.hidden) return null

      const dropdownLabel = loc(label || "Actions")
      const _bsStyle = bsStyle + (fill ? " btn-fill" : "")
      content = (
        <Dropdown
          bsSize={bsSize}
          bsStyle={_bsStyle}
          className={`inline-flex align-items-center ${fill ? "btn-fill" : ""} ${className}`}
          onClick={event => {
            const { target } = event
            target.focus()
            target.nextSibling?.click()
          }}
        >
          <CustomButton bsStyle={_bsStyle} className="grow" tabIndex="-1">
            {dropdownLabel}
          </CustomButton>
          <Dropdown.Toggle bsStyle={_bsStyle} onKeyDown={onDropdownToggleKeyDown} aria-label={dropdownLabel} />
          <Dropdown.Menu className="br-theme overflow-hidden border-0">
            {splitButtonMenus
              .filter(menu => !menu.hidden)
              .slice(1)
              .map((menu, index) => (
                <CustomButton
                  key={index}
                  tabIndex="0"
                  bsStyle={_bsStyle}
                  bsSize="small"
                  fill
                  className="btn-simple white-space-normal w-100 min-w-200px text-left br-0 m-0 inline-flex align-items-center outline-accessible-inset"
                  onClick={event => {
                    event.stopPropagation()
                    this.handleButtonClick({ ...menu, pageState })
                  }}
                  onKeyDown={onDropdownMenuButtonKeyDown}
                >
                  {menu.iconClassName && <i className={menu.iconClassName} />}
                  {` ${loc(menu.label || "MenuButton")}`}
                </CustomButton>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else if (tooltip) {
      content = (
        <ButtonWithTooltip
          btnClassName={"inline-flex align-items-center " + className}
          className={iconClassName}
          disabled={loading}
          {...{ pullRight, fill, simple, bsSize, bsStyle, tooltip, ...props }}
          key={index}
          onClick={
            typeof onClick === "function"
              ? async event => this.handleButtonClick(await onClick({ event, pageState: cloneDeep(form) }))
              : () => this.handleButtonClick({ url, linkTo, redirectTo, download, pageState })
          }
        >
          {icon && <i className={icon + (showLabel ? " mr-5px" : "")} />}
          {showLabel ? loc(label || "Button") : ""}
        </ButtonWithTooltip>
      )
    } else {
      // The onClick function props format can return an object similar
      // to the normal handling of the click, so that we can avoid providing
      // too much freedom to the scripts sandbox and keep as much as possible a consistent behavior / signature.
      // For instance if a Page needs to change the URL so that the user is directed to an external page,
      // instead of providing window.location.href in the sandbox, the onClick could instead return linkTo.
      content = (
        <CustomButton
          className={"inline-flex align-items-center " + className}
          disabled={loading}
          {...{ pullRight, fill, simple, bsSize, bsStyle, iconClassName, ...props }}
          key={index}
          onClick={
            typeof onClick === "function"
              ? async event => this.handleButtonClick(await onClick({ event, pageState: cloneDeep(form) }))
              : () => this.handleButtonClick({ url, linkTo, redirectTo, download, pageState })
          }
        >
          {icon && <i className={icon + (showLabel ? " mr-5px" : "")} />}
          {showLabel ? loc(label || "Button") : ""}
        </CustomButton>
      )
    }

    return colProps ? (
      <Col key={index} {...colProps}>
        {content}
      </Col>
    ) : (
      content
    )
  }

  // Variables coming from props should have precedence over the ones from the state.
  // This is because this component can be used either as a content type "form" or
  // as a generator of elements for the layout card.
  // In the first case the state can be managed and stored here and be self-sufficient.
  // In the second case the state is partially managed and stored here.
  getFormElement = (element, rowIndex = 0, colIndex = 0) => {
    const { form } = this.state
    const { readOnly, modelPath, obj, handlers, execComputations, fields, onSetState } = this.props
    const { title, formInputProps, buttonProps, colProps } = element

    let content
    // the title props here is only supported when using this component explicitly with { type: "form" }
    if (title && fields) {
      content = typeof title === "string" ? loc(title) : title
    } else if (buttonProps) {
      content = this.generateButton(buttonProps, rowIndex)
    } else if (formInputProps) {
      let { obj: formInputObj, field, multiple, searchEntityName, ...props } = formInputProps

      // TODO : merge this block with the other one in handleSetFormStateSync
      // for now for an unknown reason putting the code in a function does not give the same results...
      let extendedOnSetState = patch => {
        // The reason for a "&& !formInputObj" condition is to resolve the onSetState() of the "personRegistration" type formInput
        // because it is implemented in a particular way where there is no "field" ! and an "obj" as a string
        // TODO: Fix after normalizing the <PersonRegistrationComponent/>
        if (obj && onSetState && !formInputObj) onSetState(patch, execComputations)
        else this.handleSetFormState(patch, formInputProps)
      }

      if (typeof formInputObj === "string" && handlers) {
        const handler = handlers[formInputObj]
        if (!handler) addOops(loc("Invalid object $ in 'layout' script", formInputObj))
        else {
          if (handler.obj) formInputObj = handler.obj
          if (handler.onSetState)
            extendedOnSetState = debounce(patch => {
              handler.onSetState(patch, execComputations)
            }, DEFAULT_DEBOUNCE)
        }
      }

      // We don't provide "onClick" here because:
      //  - This is almost the same as "onFocus"
      //  - The click semantic is more suited for buttons
      //  - We have the "action" which is also almost equivalent but is restricted when clicking on the action icon
      const inputEvents = ["action", "onBlur", "onFocus"]
      for (const eventName of inputEvents) {
        const eventFunction = props[eventName]
        if (typeof eventFunction === "function") {
          props[eventName] = async event => {
            const eventPatch = await eventFunction({ event, pageState: cloneDeep(form) })
            if (eventPatch) extendedOnSetState(eventPatch)
          }
        }
      }

      if (typeof props.select === "function") {
        const selectFunction = props.select
        props.select = async query => {
          return selectFunction(query, cloneDeep(form))
        }
      }

      content = (
        <FormInputExtended
          obj={formInputObj || obj || form}
          readOnly={readOnly || obj?.readOnly}
          field={field}
          multiple={multiple}
          searchEntityName={searchEntityName}
          modelPath={modelPath}
          onSetState={extendedOnSetState}
          {...props}
        />
      )
    }

    return formInputProps?.colProps || buttonProps?.colProps || colProps === null || formInputProps?.type === "address" ? (
      <Fragment key={colIndex}>{content}</Fragment>
    ) : (
      <Col key={colIndex} {...(colProps || { xs: 12, sm: 6, lg: 4, className: undefined })}>
        {content}
      </Col>
    )
  }

  render() {
    const { loading } = this.state
    let {
      uri, // TODO: Remove me after release 22/11/2022
      url,
      submitButtonLabel,
      submitButtonStyle = "primary",
      submitButtonSize = "small",
      field,
      fields = [],
      buttons = [],
    } = this.props
    if (!url && uri) url = uri

    if (field) return this.getFormElement(field)

    return (
      <form onSubmit={this.handleSubmit}>
        {fields.map((rowFields, rowIndex) => {
          return (
            <Row key={rowIndex}>
              {rowFields.filter(field => !field?.hidden).map((field, colIndex) => this.getFormElement(field, rowIndex, colIndex))}
            </Row>
          )
        })}

        {url && (
          <Row>
            <Col xs={12}>
              <CustomButton
                fill
                pullRight
                type="submit"
                className="inline-flex-center"
                bsStyle={submitButtonStyle}
                disabled={loading}
                bsSize={submitButtonSize}
              >
                {loading && <i className="icn-circle-notch icn-spin icn-xs mr-5px" />}
                {loc(submitButtonLabel || "Save")}
              </CustomButton>
            </Col>
          </Row>
        )}

        {buttons.length > 0 && (
          <Row>
            <Col xs={12}>{buttons.map((buttonProps, index) => this.generateButton(buttonProps, index))}</Col>
          </Row>
        )}
      </form>
    )
  }
}

export default withRouter(FormContent)
