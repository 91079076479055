import { logout as authLogout } from "@/_services/authentication"
import { getAuthorizationToken, resetHeaderAuthorization } from "@/_services/axios"
import { getHostConfig } from "@/_services/theming"
import { preloadUserConfiguration } from "@/_services/userConfiguration"
import { localStorageKeys, searchParamToObject } from "@/_services/utils"
import axios from "axios"

import { mergeQueryParams } from "@/_services/utils"

/**
 * In our SAML integration, our server receives a POST request from the Identity Provider
 * and then redirects users to our SPA URL with a transient token passed in the query params.
 */
export async function authenticate({ history }) {
  const queryParams = searchParamToObject(window.location.search)
  const { location } = history

  const hostConfig = getHostConfig()
  const saml = queryParams.saml || hostConfig.saml

  // This AND condition is because when coming back from the external login page,
  // there might be no oidc value provided to allow us to distinguish that journey from a normal landing without sso.
  // Security measure to prevent logging in with transient tokens here.
  // "Normal" transient tokens usage is handled in PagesLayout.jsx
  if (!saml && !localStorage.getItem(localStorageKeys.SAML.ID)) return

  if (queryParams.token) {
    try {
      await axios.post("/api/core/auth/token/login", { token: queryParams.token })

      if (queryParams.sessionIndex) localStorage.setItem(localStorageKeys.SAML.SESSION_INDEX, queryParams.sessionIndex)
      if (queryParams.nameId) localStorage.setItem(localStorageKeys.SAML.NAME_ID, queryParams.nameId)
    } catch (error) {
      await authLogout()
    }
    history.replace(`${location.pathname}${mergeQueryParams(location.search, { ...queryParams, token: undefined })}`)
    return
  }

  try {
    const authToken = getAuthorizationToken()
    if (authToken) {
      await preloadUserConfiguration()
      return
    }
  } catch (error) {
    resetHeaderAuthorization()
  }

  // this request is expected to return a redirection
  const {
    data: { loginUrl },
  } = await axios.get(`/api/core/saml/${saml}`)
  window.open(loginUrl, "_self")

  localStorage.setItem(localStorageKeys.SAML.ID, saml)

  return { isRedirectingToSamlProvider: true }
}

export function cleanup() {
  for (const key in localStorageKeys.SAML) {
    localStorage.removeItem(localStorageKeys.SAML[key])
  }
}

export async function logout() {
  const samlId = localStorage.getItem(localStorageKeys.SAML.ID)
  const sessionIndex = localStorage.getItem(localStorageKeys.SAML.SESSION_INDEX)
  const nameId = localStorage.getItem(localStorageKeys.SAML.NAME_ID)
  localStorage.removeItem(localStorageKeys.SAML.ID)
  localStorage.removeItem(localStorageKeys.SAML.SESSION_INDEX)
  localStorage.removeItem(localStorageKeys.SAML.NAME_ID)

  if (samlId && sessionIndex && nameId) {
    const {
      data: { logoutUrl },
    } = await axios.post(`/api/core/saml/${samlId}/logout`, { sessionIndex, nameId })
    if (!logoutUrl) return

    let href = logoutUrl
    window.location.href = href
    return new Promise(resolve => {
      // the redirection is not instantaneous so we need a delay
      // to avoid callers of this function to have to delay their work if they rely on the outcome of this call
      setTimeout(() => resolve(), 400)
    })
  }
}
