import { copyToClipboard } from "@/_services/utils"
import cloneDeep from "lodash.clonedeep"

export function copyEntities(entities, options) {
  entities = cloneDeep(entities)
  entities.forEach(entity => cleanupData(entity, options))
  copyToClipboard(JSON.stringify(entities, null, 2))
}

export function copyEntity(entity, options) {
  entity = cloneDeep(entity)
  cleanupData(entity, options)
  copyToClipboard(JSON.stringify(entity, null, 2))
}

export async function pasteEntities(schema, options) {
  let datas = JSON.parse(await navigator.clipboard.readText())
  if (!Array.isArray(datas) && typeof datas === "object") datas = [datas] // Transform object to array of 1 object
  return datas.map(data => {
    cleanupData(data, options)
    const entity = {}
    pasteAccordingToSchema(entity, data, schema)
    return entity
  })
}

// this paste feature was developed to copy paste Product entities.
// It should work ok while in the same environment, but if you paste it from env1 to env2 (e.g. UAT to PROD)
// Then you might have issues with registrations beging different between env (like Product.persons)
// So think twice before activating showCopyPaste with other entities (talk to Thomas)
export async function pasteEntity(schema, options) {
  let pastedString = await navigator.clipboard.readText()
  let data
  data = JSON.parse(pastedString)
  if (options?.isArray && !Array.isArray(data)) data = [data]
  cleanupData(data, options)
  let entity = {}
  pasteAccordingToSchema(entity, options?.subKey ? { [options.subKey]: data } : data, schema)
  return options?.subKey ? entity[options.subKey] : entity
}

function cleanupData(entity, options) {
  // delete tech fields
  if (!options?.keepRegistration) delete entity.registration
  delete entity._insertDate
  delete entity._insertUser
  delete entity._updateDate
  delete entity._updateUser
  delete entity.__v
  delete entity.readOnly

  // remove all _id
  function removeAllIds(entity) {
    for (let key in entity) {
      if (key === "_id") {
        delete entity[key]
      } else if (entity[key] === null) {
        delete entity[key]
      } else if (typeof entity[key] === "object") {
        removeAllIds(entity[key])
      }
    }
  }
  removeAllIds(entity)
}

function pasteAccordingToSchema(entity, data, schema) {
  for (let key in schema) {
    let info = schema[key]
    if (key in data) {
      let infoType = info.type || info
      if (["String", "Number", "Boolean"].includes(infoType)) {
        entity[key] = data[key]
      } else if (infoType === "Date") {
        entity[key] = new Date(data[key])
      } else if (Array.isArray(infoType) && Array.isArray(infoType[0]) && infoType[0].length === 0) {
        entity[key] = data[key]
      } else if (Array.isArray(infoType) && Array.isArray(infoType[0]) && infoType[0][0] === "Number") {
        entity[key] = data[key]
      } else if (Array.isArray(infoType) && (infoType[0] === "String" || infoType[0] === "Number")) {
        entity[key] = data[key]
      } else if (Array.isArray(infoType)) {
        if (typeof infoType[0] === "object" && Array.isArray(data[key]) && typeof data[key][0] === "object") {
          entity[key] = []
          for (let line of data[key]) {
            let obj = {}
            entity[key].push(obj)
            if (schema[key]?.type) {
              pasteAccordingToSchema(obj, line, schema[key].type[0])
            } else {
              pasteAccordingToSchema(obj, line, schema[key][0])
            }
          }
        }
      } else if (typeof infoType === "object") {
        entity[key] = {}
        pasteAccordingToSchema(entity[key], data[key], schema[key])
      }
    }
  }
}
