import React, { createRef } from "react"
import { loc } from "@/_services/localization"
import { FormControl, InputGroup } from "react-bootstrap"
import Select, { components } from "react-select"
import { getPhoneNumberInfo } from "@/_services/utils"
import { getValues } from "@/_services/lists"

class CountrySelectWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = createRef()
    this.state = {
      isInputFocused: false,
      countryOptions: [],
      inputValue: "",
      searchValue: "",
      selectValue: { value: "" },
      isLoading: true,
      phoneObject: {},
      indexedItems: {},
    }
  }

  componentDidMount() {
    this.update()
  }

  update() {
    const { countryCallingCodesList, value } = this.props

    const options = getValues(countryCallingCodesList, val => {
      const phoneObject = getPhoneNumberInfo(value, val.values)

      this.setState({
        countryOptions: val?.values || [],
        isLoading: false,
        inputValue: phoneObject.localNumber,
        selectValue: phoneObject.callingCode,
        indexedItems: val.items,
        phoneObject,
      })
    })

    if (options.length) {
      const phoneObject = getPhoneNumberInfo(value, options)

      this.setState({
        countryOptions: options,
        isLoading: false,
        inputValue: phoneObject.localNumber,
        selectValue: phoneObject.callingCode,
        phoneObject,
      })
    }
  }

  componentWillUnmount() {
    this.containerRef.current?.removeEventListener("mousedown", this.onDomClick)
  }

  onDomClick = e => {
    const menu = this.containerRef.current?.querySelector(".select__menu")
    const containerHasTarget = this.containerRef.current?.contains(e.target)
    const menuHasTarget = menu?.contains(e.target)

    if (!containerHasTarget || !menu || !menuHasTarget) {
      this.setState({
        isInputFocused: false,
        searchValue: "",
      })
    }
  }

  handleInputFocus = () => {
    this.setState({ isInputFocused: true })
  }

  handleSearch = text => {
    this.setState({ searchValue: text })
  }

  handleChange = (from, event) => {
    const { onChange } = this.props

    this.setState(
      {
        ...(from === "select" ? { selectValue: event } : {}),
        ...(from === "input" ? { inputValue: event?.target?.value } : {}),
      },
      () => onChange({ target: { value: `${this.state.selectValue.value}${this.state.inputValue}` } }),
    )
  }

  render() {
    const { fieldName, disabled, modelFieldPath, label, className, placeholder, onClick, onKeyDown, onBlur, onFocus } = this.props
    const { isInputFocused, searchValue, inputValue, selectValue, isLoading, countryOptions } = this.state

    const selectProps = {
      searchValue,
      selectValue,
      isDisabled: disabled,
      onSearch: this.handleSearch,
    }

    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "min-content" }} ref={this.containerRef}>
          <Select
            {...selectProps}
            options={countryOptions}
            components={{
              MenuList,
              ValueContainer,
              SingleValue,
            }}
            classNamePrefix={`${fieldName}-select`}
            placeholder=""
            closeMenuOnSelect={true}
            name={`${fieldName}-emoji`}
            value={selectValue}
            onChange={ev => {
              this.setState({ isInputFocused: false })
              this.handleChange("select", ev)
            }}
            onInputFocus={this.handleInputFocus}
            onInputChange={this.handleInputChange}
            menuIsOpen={isInputFocused || undefined}
            isSearchable={false}
            styles={{
              control: css => ({
                ...css,
                minHeight: "36px",
                maxHeight: "36px",
                border: "1px solid var(--input-border-color)",
                width: "max-content",
              }),
              menu: css => ({ ...css, width: "240px" }),
              valueContainer: css => ({ ...css, fontSize: "20px" }),
              dropdownIndicator: css => ({ ...css, padding: 0 }),
            }}
          />
        </div>
        <InputGroup data-disabled={disabled}>
          <InputGroup.Addon className="form-input-addon">
            <span>{selectValue?.value || ""}</span>
          </InputGroup.Addon>
          <FormControl
            onChange={ev => this.handleChange("input", ev)}
            disabled={disabled || isLoading}
            value={inputValue}
            id={modelFieldPath}
            data-model-field-path={modelFieldPath}
            aria-label={label}
            className={className}
            type="tel"
            inputMode="tel"
            pattern=".*[0-9]*"
            bsClass="form-control"
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onClick={onClick}
          />
        </InputGroup>
      </div>
    )
  }
}

export default CountrySelectWrapper

const MenuList = props => {
  const { inputValue, onInputChange, onInputFocus } = props.selectProps

  return (
    <div>
      <input
        title="non-search-field"
        className="w-100 pd-theme border-0 bbw-1"
        type="text"
        placeholder={loc("Search")}
        value={inputValue}
        onMouseDown={e => {
          e.stopPropagation()
          e.target.focus()
        }}
        onFocus={onInputFocus}
        onChange={e =>
          onInputChange(e.currentTarget.value, {
            action: "input-change",
            prevInputValue: inputValue,
          })
        }
      />
      <components.MenuList {...props} selectProps={props.selectProps} />
    </div>
  )
}

const ValueContainer = ({ children, selectProps, ...props }) => {
  const { ValueContainer } = components

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children?.map(children, child => (child ? child : null))}
    </ValueContainer>
  )
}

const SingleValue = ({ selectProps, ...props }) => {
  const { SingleValue } = components
  const { selectValue } = selectProps

  return (
    <SingleValue {...props} selectProps={selectProps}>
      <i className={`icn-flag-cnty-${(selectValue?.value2 || "").toLowerCase()} flag-cnty`} />
    </SingleValue>
  )
}
