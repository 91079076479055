import React from "react"
import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"

import FormInput from "@/_components/FormInput"
import { loc } from "@/_services/localization"
import { getOrganizationsObj } from "@/_services/utils"

class PersonLink extends React.Component {
  render() {
    const { registration, label, children, target, colProps, className } = this.props

    if (registration && typeof registration !== "string") return loc("Invalid registration format, should be a string")

    if (!registration) {
      if (children) return <>{children}</>
      return null
    }

    const OrganizationsObj = getOrganizationsObj()
    if (OrganizationsObj[registration] && (registration === "TENANT" || OrganizationsObj[registration].type === "O")) {
      const tenantName = OrganizationsObj[registration].name
      if (label) return <FormInput readOnly value={tenantName} {...{ label, colProps }} />
      else if (tenantName) return tenantName
    }

    let content = null
    if (children && registration) {
      if (label) content = <FormInput readOnly value={children} label={label} />
      else content = children
    } else {
      if (children) content = children
      else if (registration) {
        if (label) content = <FormInput readOnly value={registration} label={label} />
        else content = registration
      }
    }

    content = (
      <Link to={`/person/${registration}`} target={target} className={className}>
        {content}
      </Link>
    )

    if (colProps) return <Col {...colProps}>{content}</Col>
    return content
  }
}

export default PersonLink
