import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import LayoutCard from "@/_components/LayoutCard"
import Table, { mergeColumns } from "@/_components/Table"
import { getEntityDisplay } from "@/_services/entity"
import { getLabel, getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { getPersonName } from "@/_services/personUtils"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  formatCurrency,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchEntities,
  searchParamToObject,
} from "@/_services/utils"
import { getFilterFunction, pruneObject, roundCurrency, toQueryString } from "basikon-common-utils"
import React, { createRef } from "react"
import { Grid } from "react-bootstrap"

class CreditLinesPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "CreditLinesPage"
    const pageConfig = getPageConfig(pageConfigKey)
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: "/credit-line/{registration}" },
      { title: "Organization", name: "organization.name", hidden: true },
      { title: "Type", name: "type", select: "creditLineType" },
      { title: "Name", name: "name" },
      { title: "Client", name: "client", linkTo: "/person/{clientRegistration}" },
      { title: "Status", name: "status", select: "creditLineStatus", badge: true },
      { title: "Date", name: "startDate", type: "date" },
      { title: "Amount", name: "financedAmountExclTax", excelFormat: "currency" },
      { title: "Asset", name: "assets", excelWidth: 50 },
      { title: "Partner", name: "partner", linkTo: "/person/{partnerRegistration}", excelWidth: 30 },
      { title: "Limit", name: "creditLimit", excelFormat: "currency" },
      { title: "Outstanding", name: "outstanding", excelFormat: "currency" },
      { title: "Pending", name: "pending", excelFormat: "currency" },
      { title: "Available", name: "available", excelFormat: "currency" },
      { title: "Stock", name: "liveStock" },
      { title: "Insert date", name: "_insertDate", type: "date" },
    ]

    this.state = {
      loading: false,
      creditLines: [],
      entityName: "CreditLine",
      componentName: "CreditLinesPage",
      entityClientRoute: "/credit-line",
      pageConfigKey,
      pageConfig,
      pinnedData: [],
      columns: mergeColumns(defaultColumns, pageConfig?.columns),
      paginationParams: null,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    this.getCreditLines()

    getList("creditLineType", () => this.setState({ loaded: true }))
    getList("creditLineStatus", () => this.setState({ loaded: true }))

    applyPageAdvancedSearchConfig(pageConfigKey, creditLineSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getCreditLines()
    }
  }

  getCreditLines = async ({ params, isAdvancedSearch, nextPagination } = {}) => {
    const { location, history } = this.props
    const { columns, entityName, creditLines: stateEntities, pageConfig } = this.state
    const queryParams = { ...searchParamToObject(location.search), ...(params || {}) }
    const include = "personsNames,assetsNames" + (columns.find(it => it.name === "organization.name" && !it.hidden) ? ",organizationName" : "")
    const getEntitiesQueryParams = {
      ...pageConfig?.defaultQuery,
      ...pruneObject(queryParams),
      include,
      ...(pageConfig?.query || {}),
    }

    this.setState({ loading: true })

    if (nextPagination) {
      const { data: moreEntities, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })
      this.setState({ creditLines: [...stateEntities, ...moreEntities], paginationParams, loading: false })
      this.preventAutoRefresh.current = true
      delete queryParams.range
      history.replace(`${location.pathname}${toQueryString(queryParams)}`)
      this.preventAutoRefresh.current = false
      return
    }

    const [{ data: creditLines, paginationParams }, pinnedData] = await Promise.all([
      getEntities(entityName, getEntitiesQueryParams),
      fetchPinnedEntities({ entityName, queryParams: getEntitiesQueryParams }),
    ])

    this.setState({ creditLines, paginationParams, pinnedData: this.formatTableData(pinnedData), loading: false })

    if (params) {
      this.preventAutoRefresh.current = true
      if (isAdvancedSearch) queryParams.page = 1
      history.replace(`${location.pathname}${mergeQueryParams(location.search, queryParams)}`)
      this.preventAutoRefresh.current = false
    }
  }

  formatTableData = data => {
    const locale = getLocale()
    return data.map(creditLine => {
      const { persons = [], quotations = [], currency, assets = [] } = creditLine
      const { personRegistration: clientRegistration, person: client = {} } = persons.find(p => p.role === "CLIENT") || {}
      const { personRegistration: partnerRegistration, person: partner = {} } = persons.find(p => p.role === "PARTNER") || {}

      return {
        ...creditLine,
        registration: creditLine.registration,
        type: creditLine.type,
        name: creditLine.name,
        client: client?.name || clientRegistration,
        clientRegistration,
        status: creditLine.status,
        partner: partner?.name || partnerRegistration,
        partnerRegistration,
        _insertDate: creditLine._insertDate,
        financedAmountExclTax: formatCurrency(quotations?.[0]?.financedAmountExclTax, locale, currency),
        assets: assets
          .map(({ asset = {} }) => asset.name)
          .filter(a => a)
          .join(", "),
        creditLimit: formatCurrency(creditLine.creditLimit, locale, creditLine.currency),
        outstanding: formatCurrency(creditLine.outstanding, locale, creditLine.currency),
        pending: formatCurrency(creditLine.pending, locale, creditLine.currency),
        available: formatCurrency(creditLine.available, locale, creditLine.currency),
        liveStock: <FormInput inArray type="number" readOnly obj={creditLine} field="liveStock" />,
        financialProduct: creditLine.financialProduct,
        startDate: creditLine.startDate,
        endDate: creditLine.endDate,
      }
    })
  }

  render() {
    const { creditLines = [], entityName, loading, entityClientRoute, pageConfig, paginationParams, columns, pinnedData } = this.state
    const { location, history } = this.props
    const title = loc(pageConfig?.title || "Credit lines")
    const queryParams = searchParamToObject(location.search)
    const filteredCreditLines = queryParams.filter?.trim() ? filterCreditLines(creditLines, queryParams.filter) : creditLines

    const data = this.formatTableData(filteredCreditLines)

    return (
      <Grid className="credit-lines-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "credit-lines-kpi", noCard: false } }]} />
        )}

        <Card
          title={title}
          action={
            <CustomButton
              fill
              pullRight
              bsSize="small"
              bsStyle="primary"
              className="inline-flex-center"
              hidden={pageConfig?.addButton?.hidden}
              onClick={() => history.push(pageConfig?.addButton?.linkTo || entityClientRoute)}
            >
              <i className="icn-plus icn-xs mr-5px" />
              {loc(pageConfig?.addButton?.label || "Add")}
            </CustomButton>
          }
        >
          <Table
            useSearchRowComponent
            exportDataFileName={title}
            getEntities={this.getCreditLines}
            searchFields={creditLineSearchFields}
            loading={loading}
            columns={columns}
            data={data}
            entityName={entityName}
            pinnedData={pinnedData}
            pageConfig={pageConfig}
            showRowsCount
            paginationParams={paginationParams}
          />
        </Card>
      </Grid>
    )
  }
}

export default CreditLinesPage

function filterCreditLines(creditLines = [], filter) {
  if (!filter) return creditLines

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return creditLines.filter(creditLine => {
    const { persons = [], quotations = [], assets = [] } = creditLine

    const typeLabel = getLabel("creditLineType", creditLine.type)
    const statusLabel = getLabel("creditLineStatus", creditLine.status)
    const { personRegistration: clientRegistration, person: client } = (persons || []).find(p => p.role === "CLIENT") || {}
    const { personRegistration: partnerRegistration, person: partner } = (persons || []).find(p => p.role === "PARTNER") || {}
    const matchAsset = assets.find(a => includes(a.assetRegistration) || includes(a?.asset?.name))

    return (
      matchAsset ||
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(client?.name) ||
      includes(partner?.name) ||
      includes(creditLine.name) ||
      includes(creditLine.status) ||
      includes(creditLine.pending) ||
      includes(clientRegistration) ||
      includes(partnerRegistration) ||
      includes(creditLine.available) ||
      includes(creditLine.liveStock) ||
      includes(creditLine._insertDate) ||
      includes(creditLine.creditLimit) ||
      includes(creditLine.outstanding) ||
      includes(creditLine.registration) ||
      includes(creditLine.internalReference) ||
      includes(creditLine.externalReference) ||
      includes(getEntityDisplay(creditLine)) ||
      includes(roundCurrency(quotations?.[0]?.financedAmountExclTax, locale))
    )
  })
}

export const creditLineSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 } },
    { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", select: "creditLineStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", select: "creditLineType", colProps: { xs: 12, sm: 4 } },
    {
      field: "client",
      colProps: { xs: 12, sm: 4 },
      select: s =>
        searchEntities("person", s).then(persons =>
          persons.map(person => ({
            value: person.registration,
            label: `${getPersonName(person)} (${person.registration})`,
          })),
        ),
    },
    {
      field: "partner",
      colProps: { xs: 12, sm: 4 },
      select: s =>
        searchEntities("person", s).then(persons =>
          persons.map(person => ({
            value: person.registration,
            label: `${getPersonName(person)} (${person.registration})`,
          })),
        ),
    },
  ],
]
