import React from "react"
import { Modal } from "react-bootstrap"
import axios from "axios"

import CustomButton from "@/_components/CustomButton"
import FormInputExtended from "@/_components/FormInputExtended"
import LayoutCard, { genericCardExists } from "@/_components/LayoutCard"
import { lowerCaseFirstLetter, getEntityServerRoute } from "basikon-common-utils"
import { generatePageCards } from "@/_components/Card"
import { executeLayout } from "@/_services/scripts"
import { addOops } from "@/_services/notification"
import { generateRows } from "@/_services/utils"
import { loc } from "@/_services/localization"

class EntityQuickAddModal extends React.Component {
  constructor(props) {
    super(props)

    const { entityName, entityType } = props

    if (!entityName) {
      console.error("Missing mandatory 'entityName' prop in <EntityQuickAddModal/>")
      this.state = {}
      return
    }

    const entityVariableName = lowerCaseFirstLetter(entityName) // CreditLine => creditLine

    const entity = {}
    if (entityType) entity.type = entityType

    this.state = {
      entity,
      serverRoute: getEntityServerRoute(entityName),
      layout: { cache: {} },
      layoutScriptName: `${entityVariableName}-layout`, // CreditLine => "creditLine-layout"
      entityVariableName,
      prevEntityVariableName: `prev${entityName}`, // CreditLine => prevCreditLine
    }
  }

  componentDidMount() {
    const { entity } = this.state

    this.executeLayout({ entity })
      .then(layout => this.setState({ layout, entity }))
      .catch(error => console.error("Error executing layout", error))
  }

  executeLayout = async ({ entity, prevEntity }) => {
    const { layout: prevLayout, layoutScriptName, entityVariableName, prevEntityVariableName } = this.state

    const layout = await executeLayout({
      prevLayout,
      layoutScriptName,
      inQuickAddModal: true,
      entityType: entity?.type,
      [entityVariableName]: entity,
      [prevEntityVariableName]: prevEntity,
    })
    return layout
  }

  getField = col => {
    const { name, colProps, formInputProps, hidden } = col
    if (hidden || !name) return

    return <FormInputExtended key={name} field={name} colProps={colProps ?? { xs: 12 }} {...formInputProps} />
  }

  handleSetEntityState = async patch => {
    if (!patch) return

    const { entity: prevEntity } = this.state
    const entity = { ...prevEntity, ...patch }

    const layout = await this.executeLayout({ entity, prevEntity })
    this.setState({ entity, layout })
  }

  handleSave = async () => {
    const { onClose } = this.props
    const { entity, serverRoute } = this.state

    try {
      const savedEntity = (await axios.post(serverRoute, entity)).data
      if (onClose) onClose(savedEntity)
    } catch (error) {
      addOops(error)
    }
  }

  getCardComponent = ({ card, layout }) => {
    const { entityVariableName, entityName, serverRoute, entity } = this.state
    const { card: cardName, props = {} } = card

    if (genericCardExists(cardName)) {
      return (
        <LayoutCard
          card={card}
          entity={entity}
          layout={layout}
          entityName={entityName}
          serverRoute={serverRoute}
          onSetState={this.handleSetEntityState}
          handlers={{ [entityVariableName]: { obj: entity, onSetState: this.handleSetEntityState } }}
          {...props}
        />
      )
    }
  }

  render() {
    const { layout, entity } = this.state
    const { title = "Add", saveButtonLabel = "Save", cancelButtonLabel = "Cancel", rows, onClose } = this.props

    return (
      <Modal show={true} onHide={() => onClose()} bsSize="large" backdrop="static" className="person-quick-add-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {rows?.length > 0
            ? generateRows({ getField: this.getField, rows, entity, handleSetState: this.handleSetEntityState })
            : generatePageCards({ layout, getCardComponent: this.getCardComponent })}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={() => onClose()} label={cancelButtonLabel} />
          <CustomButton bsStyle="primary" bsSize="sm" fill onClick={() => this.handleSave(entity)} label={saveButtonLabel} />
        </Modal.Footer>
      </Modal>
    )
  }
}

export default EntityQuickAddModal
