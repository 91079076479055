/**
 * When throwing errors, we want the following things:
 *  - A HTTP response status code, if the error is handled by APIs,
 *  - A stable error code, if the error is specific:
 *    - so that developers can rely on it to test APIs and code reactions to the responses,
 *    - so that we can auto generate API documentation
 *  - The error message in the default language of the platform, with raw placeholders
 *  - The error message translated into the user language, with placeholders replaced
 *  - The dynamic arguments used to replace placeholders
 *  - Things must work the same when used in scripts and scripts call other scripts.
 *
 * Error code nomenclature:
 *  - Keep codes as generic as possible to maximize reusability
 *  - For security do not reveal information on the platform structure in the code
 */

const genericErrors = {
  EMPTY_AGGREGATE_PIPELINE: "400|EMPTY_AGGREGATE_PIPELINE|Pipeline is empty|",
  EMPTY_BODY_ARRAY: "400|EMPTY_BODY_ARRAY|Body payload is an empty array|",
  ENTITY_REGISTRATION_NOT_FOUND: "404|ENTITY_REGISTRATION_NOT_FOUND|Entity $ with identifier $ not found|",
  INVALID_ARRAY_OR_FUNCTION_FIELD: "400|INVALID_ARRAY_OR_FUNCTION_FIELD|Field $ must be an array or a function|",
  INVALID_ARRAY_FIELD: "400|INVALID_ARRAY_FIELD|Field $ must be an array|",
  INVALID_BODY_ARRAY: "400|INVALID_BODY_ARRAY|Body payload must be an array|",
  INVALID_DATE: "400|INVALID_DATE|Invalid date $|",
  INVALID_DATE_FIELD: "400|INVALID_DATE_FIELD|Field $ must be a date|",
  INVALID_FIELD_VALUE: "400|INVALID_FIELD_VALUE|Field $ has an invalid value $|",
  INVALID_FIELD_IN_HEADERS: "400|INVALID_FIELD_IN_HEADERS|Header field $ is invalid|",
  INVALID_FIELD_IN_QUERY: "400|INVALID_FIELD_IN_QUERY|Query field $ is invalid|",
  INVALID_FIELD_IN_BODY: "400|INVALID_FIELD_IN_BODY|Body field $ is invalid|",
  INVALID_FIELD_IN_PATH: "400|INVALID_FIELD_IN_PATH|Path field $ is invalid|",
  INVALID_FIELD_IN_CONFIG: "400|INVALID_FIELD_IN_CONFIG|Config field $ is invalid|",
  INVALID_FILE_EXTENSION: "400|INVALID_FILE_EXTENSION|Invalid file extension, should be one of $|",
  INVALID_IBAN: "400|INVALID_IBAN|IBAN $ is invalid|",
  INVALID_JSON_FIELD: "400|INVALID_JSON_FIELD|Field $ must be a JSON|",
  INVALID_INTEGER_FIELD: "400|INVALID_INTEGER_FIELD|Field $ must be an integer|",
  INVALID_NON_EMPTY_STRING_FIELD: "400|INVALID_NON_EMPTY_STRING_FIELD|Field $ must be a non-empty string|",
  INVALID_NON_EMPTY_OBJECT_FIELD: "400|INVALID_NON_EMPTY_OBJECT_FIELD|Field $ must be a non-empty object|",
  INVALID_NUMBER: "400|INVALID_NUMBER_FIELD|Invalid number $|",
  INVALID_NUMBER_FIELD: "400|INVALID_NUMBER_FIELD|Field $ must be a number|",
  INVALID_POSITIVE_NUMBER_FIELD: "400|INVALID_POSITIVE_NUMBER_FIELD|Field $ must be a positive number|",
  INVALID_PERIOD: "400|INVALID_PERIOD|Invalid period|",
  INVALID_STRING_FIELD: "400|INVALID_STRING_FIELD|Field $ must be a string|",
  INVALID_XML: "400|INVALID_XML|Invalid XML|",
  MISSING_BODY_PAYLOAD: "400|MISSING_BODY_PAYLOAD|Body payload is mandatory|",
  MISSING_CONFIG: "400|MISSING_CONFIG|Missing config $|",
  MISSING_FIELD_IN_BODY: "400|MISSING_FIELD_IN_BODY|Missing field $ in body|",
  MISSING_FIELD_IN_CONFIG: "400|MISSING_FIELD_IN_CONFIG|Missing field $ in config|",
  MISSING_FIELD_IN_QUERY: "400|MISSING_FIELD_IN_QUERY|Missing field $ in query|",
  MISSING_FIELD_IN_ARGS: "400|MISSING_FIELD_IN_ARGS|Missing field $ in arguments|",
  MISSING_FILE_IN_REQUEST: "400|MISSING_FILE_IN_REQUEST|Missing file in request|",
  MISSING_LOCALE: "400|MISSING_LOCALE|Missing locale|",
  MISSING_MANDATORY_QUERY_FIELDS: "400|MISSING_MANDATORY_QUERY_FIELDS|Missing mandatory query fields|",
  UNAVAILABLE_SOFTWARE: "500|UNAVAILABLE_SOFTWARE|Software $ is not available|",
  UNSUPPORTED_ENTITY: "400|UNSUPPORTED_ENTITY|Entity $ is not supported|",
  VALUE_ALREADY_EXISTS: "400|VALUE_ALREADY_EXISTS|Value $ already exists|",
  STATUS_PATCH_PROHIBITED: "400|STATUS_PATCH_PROHIBITED|Status modification prohibited, workflow must be used|",
}

export { genericErrors }
