import React from "react"
import { Col, Row } from "react-bootstrap"
import get from "lodash.get"

import PanelInner from "@/_components/PanelInner"
import CustomButton from "@/_components/CustomButton"
import TableExtended from "@/_components/TableExtended"
import FormInputExtended from "@/_components/FormInputExtended"
import { formatIban, truncate, PROPS_ } from "basikon-common-utils"
import { copyToClipboard, formatDate } from "@/_services/utils"
import { getOptions } from "@/_services/userConfiguration"
import { getLocale, loc } from "@/_services/localization"
import { mergeColumns } from "@/_components/Table"

const defaultInputFields = [
  { field: "bankIban", type: "iban", label: "IBAN", debounce: true, colProps: { xs: 12, sm: 5 } },
  { field: "bankBic", label: "BIC", debounce: true, colProps: { xs: 12, sm: 3 } },
  { field: "bankName", label: "Bank name", debounce: true, colProps: { xs: 12, sm: 4 } },
  { field: "bankOwner", label: "Bank account owner", debounce: true, colProps: { xs: 12, sm: 5 } },
  { field: "bankOpeningDate", label: "Opening date", type: "date", colProps: { xs: 12, sm: 3 } },
  { field: "bankPreferredWithdrawalDate", label: "Preferred withdrawal date", select: "bankPreferredWithdrawalDate", colProps: { xs: 12, sm: 4 } },
]

const defaultPreviewFields = [
  { field: "name", colProps: { xs: 12 }, badge: true },
  { field: "bankOwner", colProps: { xs: 12 } },
  { field: "bankIban", colProps: { xs: 12 }, type: "iban" },
  { field: "bankBic", colProps: { xs: 12, md: 3 } },
]

class FormInputBankAccount extends React.Component {
  handleSetState = patch => {
    if (!patch) return

    const { onSetState } = this.props

    if ("bankIban" in patch) {
      if (patch.bankIban) patch.bankIban = patch.bankIban.replace(/ /g, "") // Remove spaces (when pasted)
    }

    onSetState(patch)
  }

  renderPreview = ({ obj, rootField, fields, previewFields }) => {
    const _fields = (Array.isArray(previewFields) && previewFields) || fields || defaultPreviewFields
    const bankAccount = rootField ? get(obj, rootField) : obj

    return (
      <Col xs={12} md={4} lg={3}>
        <Row className="mb-5">
          {_fields
            .map((field, index) => {
              const fieldName = field?.field || field
              const defaultField = defaultPreviewFields.find(f => f.field === fieldName)

              // Default props
              if (typeof field === "string") field = defaultField
              else if (defaultField) field = { ...defaultField, ...field }

              const value = get(bankAccount, fieldName)
              const colProps = field?.colProps || { xs: 12 }
              if (!value) return null

              const props = bankAccount?.[`${PROPS_}${fieldName}`]
              if (props) field = { ...field, ...props }

              let formattedValue = value
              if (field?.type === "iban") {
                formattedValue = (
                  <span>
                    {formatIban(value)}
                    <i className="icn-clone icn-xs c-pointer ml-5px text-info" onClick={() => copyToClipboard(value)} />
                  </span>
                )
              } else if (field?.type === "date") formattedValue = formatDate(value, getLocale())
              else if (field?.badge) {
                formattedValue = (
                  <CustomButton
                    fill
                    round
                    tabIndex="-1"
                    bsSize={field.bsSize || "xs"}
                    className="c-default btn-badge"
                    bsStyle={field.bsStyle || "info"}
                  >
                    {value?.length > 30 ? <span title={value}>{truncate(value, 30)}</span> : value}
                  </CustomButton>
                )
              }

              return (
                <Col key={index} className={field?.className} {...colProps}>
                  {formattedValue}
                </Col>
              )
            })
            .filter(f => f)}
        </Row>
      </Col>
    )
  }

  render() {
    let { obj, field: rootField /**ex: "bankAccount" or "bankAcccounts[0]" */, onSetState, modelPath, readOnly, fields, isPreview } = this.props
    const { inputFields, previewFields } = getOptions("bankAccount", {})

    if (isPreview) return this.renderPreview({ obj, rootField, fields, previewFields })

    // Priority to user configuration, fallback to "fields" prop, otherwise use "defaultFields"
    let _fields = inputFields || fields || defaultInputFields

    return _fields.map((field, key) => {
      const fieldName = field?.field || field

      if (fieldName === "sepaMandates") {
        const formInputProps = typeof field === "string" ? {} : field
        return <SepaMandates obj={obj} onSetState={onSetState} readOnly={readOnly} {...formInputProps} />
      }

      const defaultField = defaultInputFields.find(f => f.field === fieldName)

      // Default props
      if (typeof field === "string") field = defaultField
      else if (defaultField) field = { ...defaultField, ...field }

      const props = {
        ...field,
        obj,
        modelPath,
        readOnly,
        onSetState: this.handleSetState,
        field: rootField ? `${rootField}.${field.field}` : field.field,
      }
      return <FormInputExtended key={key} {...props} />
    })
  }
}

export default FormInputBankAccount

function SepaMandates(props) {
  const {
    obj,
    onSetState,
    readOnly,
    collapse,
    columns,
    hidden,
    colProps = { xs: 12 },
    title = "SEPA mandates",
    showSepaMandateAdd,
    showSepaMandateDelete,
    showSepaMandateDuplicate,
  } = props

  if (hidden) return null

  let onRowChange
  let onRowAdd
  if (!readOnly) {
    onRowChange = ({ row, patch }) => {
      const sepaMandates = [...(obj.sepaMandates || [])]
      sepaMandates[row._index] = { ...sepaMandates[row._index], ...patch }
      return onSetState({ sepaMandates })
    }
    onRowAdd = () => {
      return { sepaMandates: [...(obj.sepaMandates || []), {}] }
    }
  }

  return (
    <Col {...colProps}>
      <PanelInner title={loc(title)} collapse={collapse ?? true}>
        <TableExtended
          entity={{}}
          filter={false}
          pageInUrl={false}
          readOnly={readOnly}
          onSetState={onSetState}
          data={obj.sepaMandates || []}
          showAdd={showSepaMandateAdd}
          showDelete={showSepaMandateDelete}
          showDuplicate={showSepaMandateDuplicate}
          onRowChange={onRowChange}
          onRowAdd={onRowAdd}
          columns={mergeColumns(
            [
              { title: "Name", name: "rumName", field: "rumName", type: "text" },
              { title: "Reference", name: "rumReference", field: "rumReference", type: "text" },
              { title: "Status", name: "rumStatus", field: "rumStatus", select: "sepaMandateStatus" },
              {
                title: "Signature date",
                name: "rumDateOfSignature",
                field: "rumDateOfSignature",
                type: "date",
                formInputProps: { inArray: false, readOnly },
                colProps: { xs: 12, md: 3, lg: 3 },
              },
              {
                title: "Signature place",
                name: "rumPlaceOfSignature",
                field: "rumPlaceOfSignature",
                type: "text",
                formInputProps: { inArray: false, readOnly },
                colProps: { xs: 12, md: 3, lg: 3 },
              },
              {
                title: "Type",
                name: "rumType",
                field: "rumType",
                select: "rumType",
                formInputProps: { inArray: false, readOnly },
                colProps: { xs: 12, md: 3, lg: 3 },
              },
              {
                title: "Frequency",
                name: "rumFrequency",
                field: "rumFrequency",
                select: "rumFrequency",
                formInputProps: { inArray: false, readOnly },
                colProps: { xs: 12, md: 3, lg: 3 },
              },
            ],
            columns,
          )}
        />
      </PanelInner>
    </Col>
  )
}
